import useVuelidate from '@vuelidate/core'
import { required, email, helpers, sameAs, sameAsRawValue } from '@vuelidate/validators'

const AcceptInvitation = {
    template: /*html*/ `

        <div class="section-header">
            <p>Welcome {{ user.first_name }}! Please choose a password below, and proceed to the dashboard.</p>
        </div>

        <form class="login-form" method="post" action="" autocomplete="off">

            <div class="form-group">
                <Password name="password" :class="{'p-invalid': v$.password.$error}" v-model="password" :toggleMask="true" />
            </div>

            <div class="form-group-splitter">
                <button type="submit" class="button button-primary low-button w-auto">
                    Save password
                </button>
            </div>
        </form>
        `,
    props: ['user'],
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            password: null,
        }
    },
    validations() {
        let validationRules = {
            password: { required },
        }

        return validationRules
    },

    methods: {},
}

export { AcceptInvitation }
