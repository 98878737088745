import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { format, parse } from 'date-fns';
import { nl as dateFnsNL }  from 'date-fns/locale';
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect';
import { Dutch } from "flatpickr/dist/l10n/nl.js";
import Fuse from 'fuse.js'
import validator from 'validator';

import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies';

import VueNumerals from 'vue-numerals';

import FileUpload from 'primevue/fileupload';
import Calendar from 'primevue/calendar';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmPopup from 'primevue/confirmpopup';
import Image from 'primevue/image';

import ConfirmationService from 'primevue/confirmationservice';
import PrimeVue from 'primevue/config';

import { RegisterForm } from './components/register-form';
import { LawfirmForm } from './components/lawfirm-form';
import { CopyrightRegistrationForm } from './components/copyright-registration-form';
import { StartClientRegistrationForm } from './components/start-client-registration-form';
import { SearchForm } from './components/search-form';
import { LoginForm } from './components/login-form';
import { PasswordForm } from './components/password-form';
import { AcceptInvitation } from './components/accept-invitation';
import { PaymentForm } from './components/payment-form';
import { Users } from './components/users';
import { UserForm } from './components/user-form';

const app = createApp({
    components: {
        'copyright-registration-form': CopyrightRegistrationForm,
        'start-client-registration-form': StartClientRegistrationForm,
        'register-form': RegisterForm,
        'lawfirm-form': LawfirmForm,
        'search-form': SearchForm,
        'login-form': LoginForm,
        'password-form': PasswordForm,
        'users': Users,
        'user-form': UserForm,
        'accept-invitation': AcceptInvitation,
        'confirmpopup': ConfirmPopup
    },
    provide() {
        return {
            'Fuse': Fuse,
            'format': format,
            'parse': parse,
            'Dutch': Dutch,
            'dateFnsNL': dateFnsNL,
            'validator': validator,
            'weekSelect': weekSelect,
        }
    },
    data() {
        return {
            format,
            parse,
            dateFnsNL,
            mainMenuOpened: false,
            mainMenuVisible: false,
            selectedRemoveRegistrationCode: null
        }
    },
    methods: {
        toggleMenu() {
            if (this.mainMenuOpened === true) {
                this.mainMenuOpened = false;
                window.setTimeout(() => {
                    this.mainMenuVisible = false;
                }, 400);
            } else {
                this.mainMenuVisible = true;
                window.setTimeout(() => {
                    this.mainMenuOpened = true;
                }, 0);
            }
        },
        removeRegistration(event, registrationCode) {
            event.preventDefault();

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to remove this registration?',
                header: 'Are you sure',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.selectedRemoveRegistrationCode = registrationCode;
                    setTimeout(() => {
                        this.$refs.removeRegistrationForm.submit();
                    }, 0);
                }
            });
        },
        forceTakeover(event) {
            event.preventDefault();
            const url = event.target.href;
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to take over this registration? The client will not be able to edit it anymore!',
                header: 'Are you sure?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    window.location.href = url;
                }
            });
        },
        scrollToRef(e, ref) {
            e.preventDefault();
            const reference = this.$refs[ref];
            const yOffset = -120;
            const y = reference.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });            
        },
        logout(event) {
            event.preventDefault();
            this.$refs.logoutForm.submit();
        }
    }
});

Sentry.init({
    app,
    dsn: "https://cf8198249cd6de2e477bc37b673dc01a@o4504515018162176.ingest.sentry.io/4506315319869440",
});

app.use(PrimeVue);
app.use(VueAxios, axios);
app.use(VueCookies);
app.use(ConfirmationService);

app.use(VueNumerals, {
    locale: 'nl-nl'
});

app.component('FileUpload', FileUpload);
app.component('Calendar', Calendar);
app.component('SelectButton', SelectButton);
app.component('Textarea', Textarea);
app.component('RadioButton', RadioButton);
app.component('Checkbox', Checkbox);
app.component('InputText', InputText);
app.component('Password', Password);
app.component('Dropdown', Dropdown);
app.component('Dialog', Dialog);
app.component('Button', Button);
app.component('InputMask', InputMask);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('ConfirmPopup', ConfirmPopup);
app.component('PaymentForm', PaymentForm);
app.component('Image', Image);

window.vueApp = app.mount('#site-container');