import useVuelidate from '@vuelidate/core'
import { required, email, helpers, sameAs } from '@vuelidate/validators'

const SearchForm = {
    template: /*html*/ `

        <Dialog header="Attention" v-model:visible="noImagesNoWorkDialogVisible" :modal="true" :closable="true">
            Please note that the name of the work as well as images of the work have not been published in the public part of the Copyrights Register.
        </Dialog>

        <Dialog header="Attention" v-model:visible="noImagesDialogVisible" :modal="true" :closable="true">
            Please note that there are no images of this work available in the Copyrights Register.
        </Dialog>

        <Dialog header="Attention" v-model:visible="noWorkDialogVisible" :modal="true" :closable="true">
            Please note that the name of the work has not been published in the public part of the Copyrights Register.
        </Dialog>

        <Dialog class="view-registration-payment-modal" v-model:visible="showPaymentForm" :modal="true" :closable="true">
            <template #header>
                <div class="stepper-wrapper">
                    <div class="stepper-item" :class="{'active': currentPaymentStep == 1, 'completed': currentPaymentStep > 1 }">
                        <div class="step-counter">1</div>
                        <div class="step-name">Overview</div>
                    </div>
                    <div class="stepper-item" :class="{'active': currentPaymentStep == 2, 'completed': currentPaymentStep > 2 }">
                        <div class="step-counter">2</div>
                        <div class="step-name">Billing info</div>
                    </div>
                    <div class="stepper-item" :class="{'active': currentPaymentStep == 3, 'completed': currentPaymentStep > 3 }">
                        <div class="step-counter">3</div>
                        <div class="step-name">Payment method</div>
                    </div>
                    <div class="stepper-item" :class="{ 'completed': currentPaymentStep == 4 }">
                        <div class="step-counter">4</div>
                        <div class="step-name">Receive registration</div>
                    </div>
                </div>
            </template>

            <div class="payment-steps-container">
                <div v-if="currentPaymentStep == 1" class="payment-step-container">
                    <p>
                        After receipt of your payment, you shall receive access to Registration Form of the work as registered in the Copyrights Register.
                    </p>

                    <p>
                        Please note that the Registration Form of this work shall include the following details:
                    </p>

                    <ul>
                        <li>- Registration number of the work; </li>
                        <li v-if="selectedRegistration.publish_work_name == 1">- Name of the work;</li>
                        <li v-if="selectedRegistration.images_may_be_shown == 1">- {{selectedRegistration.images_count}} high resolution photos of the work;</li>
                        <li>- The date and time of the registration;</li>
                        <li>- Extension date of the work; </li>
                        <li v-if="selectedRegistration.publish_creation_date == 1">- The date of creation of the work;</li>
                        <li v-if="selectedRegistration.publish_transfer_date == 1">- The date(s) of transfer(s) of the work;</li>
                        <li>- The name of the copyright owner; and</li>
                        <li>- The contact details of the IP Representative.</li>
                    </ul>

                    <div class="view-registration-price">
                        4,<sup>50</sup>
                        <span>Your payment is processed by our secured payment provider.</span>
                    </div>
                </div>

                <div v-if="currentPaymentStep == 2" class="payment-step-container">
                    <p>
                        Please enter the information you want to appear on the invoice. Only the email address is mandatory, as we are sending the download link and the invoice to that address.
                    </p>
                    <div class="grid">

                        <div class="col-12">
                            <div class="p-inputgroup">
                                <InputText :class="{'p-invalid': v$.invoiceFields.email.$error}" placeholder="Email address *" v-model="invoiceFields.email" />
                                <small v-for="error of v$.invoiceFields.email.$errors" class="w-100 p-error text-left">{{error.$message}}</small>
                            </div>
                        </div>

                        <div class="col-12 md:col-6">
                            <div class="p-inputgroup">
                                <InputText placeholder="First name" v-model="invoiceFields.firstName"/>
                            </div>
                        </div>

                        <div class="col-12 md:col-6">
                            <div class="p-inputgroup">
                                <InputText placeholder="Last name" v-model="invoiceFields.lastName" />
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="p-inputgroup">
                                <InputText placeholder="Company name" v-model="invoiceFields.company" />
                            </div>
                        </div>

                        <div class="col-12 md:col-9">
                            <div class="p-inputgroup">
                                <InputText placeholder="Street name" v-model="invoiceFields.street"/>
                            </div>
                        </div>

                        <div class="col-12 md:col-3">
                            <div class="p-inputgroup">
                                <InputText placeholder="Housenumber" v-model="invoiceFields.housenumber" />
                            </div>
                        </div>

                        <div class="col-12 md:col-4">
                            <div class="p-inputgroup">
                                <InputText placeholder="Zipcode" v-model="invoiceFields.zipcode" />
                            </div>
                        </div>

                        <div class="col-12 md:col-8">
                            <div class="p-inputgroup">
                                <InputText placeholder="City" v-model="invoiceFields.city" />
                            </div>
                        </div>

                        <div v-if="showStateSelector(invoiceFields.countryId)" class="col-12 md:col-6">
                            <div class="p-inputgroup">
                                <InputText placeholder="State" v-model="invoiceFields.state" />
                            </div>
                        </div>

                        <div class="col-12" :class="{'md:col-6' : showStateSelector(invoiceFields.countryId)}">
                            <div class="p-inputgroup">
                                <Dropdown :showClear="true" v-model="invoiceFields.countryId" optionValue="id" optionLabel="country_name" :options="countries" placeholder="Choose country" />
                            </div>
                        </div>
                        
                        <div class="col-12" v-if="showVatNumber">
                            <div class="p-inputgroup">
                                <InputText :class="{'p-invalid': v$.invoiceFields.vatNumber.$error}" :placeholder="isNL(invoiceFields.countryId) || !isEU(invoiceFields.countryId) ? 'VAT number' : 'VAT number *'" v-model="invoiceFields.vatNumber" />
                                <small v-for="error of v$.invoiceFields.vatNumber.$errors" class="w-100 p-error text-left">{{error.$message}}</small>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="p-inputgroup f-direction-row">
                                <Checkbox id="terms" :binary="true" :class="{'p-invalid': v$.invoiceFields.terms.$error}" v-model="invoiceFields.terms" />
                                <label class="checkbox-label" for="terms">I agree with the <a href="terms-and-conditions" target="_blank">terms and conditions</a></label>
                            </div>
                            <small v-for="error of v$.invoiceFields.terms.$errors" class="p-error text-left">{{error.$message}}</small>
                        </div>

                        <div class="col-12">
                            <div class="p-inputgroup f-direction-row">
                                <Checkbox id="privacy" :binary="true" :class="{'p-invalid': v$.invoiceFields.privacy.$error}" v-model="invoiceFields.privacy" />
                                <label class="checkbox-label" for="privacy">I agree with the <a href="privacy-policy" target="_blank">privacy policy</a></label>
                            </div>
                            <small v-for="error of v$.invoiceFields.privacy.$errors" class="p-error text-left">{{error.$message}}</small>
                        </div>
                    </div>
                </div>
                <div v-if="currentPaymentStep == 3" class="payment-step-container">
                    <div class="failed-payment-container" v-if="paymentFailed">
                        Unfortunately, your payment has failed. Please try again.
                    </div>
                    <div class="pricing-table-container">
                        <table class="pricing-table">
                            <tr v-if="!vatReversed">
                                <td>Base fee</td>
                                <td>&euro; {{ numeralFormat(4.5, '0,0.00') }}</td>
                            </tr>
                            <tr v-if="!vatReversed">
                                <td>VAT (21%)</td>
                                <td>&euro; {{ numeralFormat(0.95, '0,0.00') }}</td>
                            </tr>
                            <tr>
                                <td>Total amount due</td>
                                <td v-if="vatReversed">&euro; {{ numeralFormat(4.50, '0,0.00') }}</td>
                                <td v-if="!vatReversed">&euro; {{ numeralFormat(5.45, '0,0.00') }}</td>
                            </tr>                                                
                        </table>
                    </div>
                    <div class="payment-method-selector">
                        <div v-for="paymentMethod in paymentMethods" class="payment-method-container" :class="{'selected' : selectedPaymentMethod == paymentMethod.id, 'no-arrow': paymentMethod.id != 'ideal'}">
                            <label class="radio-container" :class="{'selected' : selectedPaymentMethod == paymentMethod.id}" @click="selectedPaymentMethod = paymentMethod.id">
                                <img :src="paymentMethod.image" :alt="'Pay with ' + paymentMethod.name">{{paymentMethod.name}}
                                <input type="radio" :checked="selectedPaymentMethod == paymentMethod.id" name="paymentMethod" :value="paymentMethod.id">
                                <span class="checkmark"></span>
                            </label>

                            <div v-if="paymentMethod.id == 'ideal'" class="payment-method-content">
                                <Dropdown v-model="selectedIssuer" :options="issuers" optionLabel="name" placeholder="Choose your bank">
                                    <template #option="issuer">
                                        <div class="p-dropdown-issuer-option">
                                            <img :alt="issuer.option.name" :src="issuer.option.image" />
                                            <span>{{issuer.option.name}}</span>
                                        </div>
                                    </template>

                                    <template #value="issuer">
                                        <div class="p-dropdown-issuer-value" v-if="issuer.value">
                                            <img :alt="issuer.value.name" :src="issuer.value.image" />
                                            <span>{{issuer.value.name}}</span>
                                        </div>
                                        <span v-else>
                                            {{issuer.placeholder}}
                                        </span>
                                    </template>
                                </Dropdown>
                                <input v-if="selectedIssuer" type="hidden" name="issuer" :value="selectedIssuer.id">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer v-if="currentPaymentStep < 4">
                <Button class="previous-button" v-if="currentPaymentStep > 1" icon="pi pi-arrow-left" iconPos="left" label="Previous" @click="previousPaymentStep()" />
                <Button class="next-button" v-if="currentPaymentStep < 3" icon="pi pi-arrow-right" iconPos="right" label="Next" @click="nextPaymentStep()" />
                <Button class="next-button" v-if="currentPaymentStep == 3" icon="pi pi-lock" iconPos="left" label="Make payment" @click="nextPaymentStep()" />
            </template>

            <form ref="paymentForm" action="query-register" method="post">
                <input type="hidden" name="registrationCode" :value="selectedRegistration.registration_code">
                <input type="hidden" name="vatNumber" :value="invoiceFields.vatNumber">
                <input type="hidden" name="firstName" :value="invoiceFields.firstName">
                <input type="hidden" name="lastName" :value="invoiceFields.lastName">
                <input type="hidden" name="street" :value="invoiceFields.street">
                <input type="hidden" name="housenumber" :value="invoiceFields.housenumber">
                <input type="hidden" name="zipcode" :value="invoiceFields.zipcode">
                <input type="hidden" name="city" :value="invoiceFields.city">
                <input type="hidden" name="state" :value="invoiceFields.state">
                <input type="hidden" name="email" :value="invoiceFields.email">
                <input type="hidden" name="countryId" :value="invoiceFields.countryId">
                <input type="hidden" name="company" :value="invoiceFields.company">
                <input type="hidden" name="paymentMethod" :value="selectedPaymentMethod">
                <input v-if="selectedIssuer" type="hidden" name="issuer" :value="selectedIssuer.id">
            </form>

        </Dialog>

        <div class="search-register-container">
            <div class="search-form">
                <div class="quick-search-container">
                    <label for="registrationCode">Registration ID</label>
                        
                    <div class="quick-search-form-container">
                        <InputMask v-model="registrationCode" mask="9999-9999-9999"/>
                        <Button label="Search" @click="search('basic')" />
                    </div>
                    <p class="search-form-error" v-if="showTooLittleInfoBasic">Please enter a registration ID</p>
                </div>
                
                <a href="#" class="advanced-search-toggle" @click.prevent="showAdvancedSearch = true">Advanced search</a>
                
            </div>

            <div class="advanced-search-container" v-if="showAdvancedSearch">
                <div class="inputgroups">

                    <div v-if="1==2" class="inputgroup">
                        <label for="registrationCode">Registration ID</label>
                        <InputMask id="registrationCode" v-model="advancedFields.advancedRegistrationCode" mask="9999-9999-9999"/>
                    </div>
                    <div class="inputgroup">
                        <label for="firstRegistrant">Name registrant</label>
                        <InputText id="firstRegistrant" v-model="advancedFields.firstRegistrant" />
                    </div>
                    
                    <div class="inputgroup">
                        <label for="registrationDate">Registration date</label>
                        <Calendar v-model="advancedFields.selectedDate" dateFormat="dd/mm/yy" />
                    </div>

                    <div class="inputgroup">
                        <label for="categorySelector">Class</label>
                        <Dropdown @change="advancedFields.selectedSubcategory = null" class="p-dropdown-lg" id="categorySelector" :showClear="true" :options="categories" optionValue="id" optionLabel="name" v-model="advancedFields.selectedCategory"></Dropdown>
                    </div>
                    
                    <div class="inputgroup">
                        <label for="subcategorySelector">Subclass</label>
                        <Dropdown class="p-dropdown-lg" id="subcategorySelector" :showClear="true" :options="subcategories" optionValue="id" optionLabel="name" v-model="advancedFields.selectedSubcategory"></Dropdown>
                        <small v-for="error of v$.advancedFields.selectedSubcategory.$errors" class="p-error text-left">{{error.$message}}</small>
                    </div>


                </div>
                <Button label="Search" @click="search('advanced')" />
                <p class="search-form-error" v-if="showTooLittleInfoAdvanced">Please enter at least 1 search field</p>
            </div>

            <div v-if="searched && foundRegistrations.length > 0" class="search-result-container" ref="foundRegistrationsContainer">
                <h3>Found registrations</h3>

                <div class="found-registration-container" ref="foundRegistrationsContainer">
                    <div class="found-registration" v-for="foundRegistration in foundRegistrations">
                        <h2>
                            Registration code: <span>{{foundRegistration.registration_code}}</span>
                            <button v-if="foundRegistration.publish_work_name == 0 && foundRegistration.images_may_be_shown == 0" @click="noImagesNoWorkDialogVisible = true" class="info-button"><i class="pi pi-info-circle"></i></button>
                            <button v-if="foundRegistration.publish_work_name == 1 && foundRegistration.images_may_be_shown == 0" @click="noImagesDialogVisible = true" class="info-button"><i class="pi pi-info-circle"></i></button>
                            <button v-if="foundRegistration.publish_work_name == 0 && foundRegistration.images_may_be_shown == 1" @click="noWorkDialogVisible = true" class="info-button"><i class="pi pi-info-circle"></i></button>
                        </h2>
                        <div class="found-registration-content">
                            <div class="found-registration-image">
                                <img v-if="foundRegistration.main_image && foundRegistration.images_may_be_shown == 1" onerror="this.src='/images/registration-placeholder.png'" :src="'/image-proxy/' + foundRegistration.main_image.id" :alt="foundRegistration.main_image.file_name" class="product-image">
                                <img v-else src="/images/registration-placeholder.png" alt="Placeholder" class="product-image">
                            </div>
                            <div class="found-registration-information">
                                <div class="found-registration-information-top">
                                    <p>Class: <strong>{{foundRegistration.registration_category.code}}. {{foundRegistration.registration_category.name}} / {{foundRegistration.registration_subcategory.code}}. {{foundRegistration.registration_subcategory.name}}</strong></p>
                                    <p v-if="foundRegistration.publish_registration_date == 1">Registered at: <strong>{{foundRegistration.created_at}}</strong></p>
                                    <p>
                                        Copyright owners: <strong>{{ getCopyrightOwnerNames(foundRegistration.copyright_owners) }}</strong>
                                    </p>
                                </div>
                                <div class="found-registration-information-bottom">
                                    <a href="#" class="button button-primary low-button hidden-desktop" @click="openPaymentForm($event, foundRegistration);">View</a>
                                    <a href="#" class="button button-primary low-button hidden-phone" @click="openPaymentForm($event, foundRegistration);">View registration</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="searched && foundRegistrations.length == 0" class="search-result-container" ref="foundRegistrationsContainer">
                <p>No registrations found that meet the criteria</p>
            </div>
        </div>
        `,
    props: ['categories', 'paymentMethods', 'issuers', 'failedPayment'],
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            registrationCode: null,
            advancedFields: {
                //advancedRegistrationCode: null,
                firstRegistrant: null,
                selectedCategory: null,
                selectedSubcategory: null,
                selectedDate: null,
            },
            selectedRegistration: null,
            showPaymentForm: false,
            showAdvancedSearch: false,
            currentPaymentStep: 1,
            foundRegistrations: [],
            selectedPaymentMethod: null,
            selectedIssuer: null,
            showTooLittleInfoAdvanced: false,
            showTooLittleInfoBasic: false,
            noImagesNoWorkDialogVisible: false,
            noImagesDialogVisible: false,
            noWorkDialogVisible: false,
            paymentFailed: false,
            mollie: null,
            loading: false,
            searched: false,
            invoiceFields: {
                vatNumber: null,
                firstName: null,
                lastName: null,
                street: null,
                housenumber: null,
                zipcode: null,
                city: null,
                state: null,
                email: null,
                company: null,
                countryId: null,
            },
            countries: [],
        }
    },
    validations() {
        let validationRules = {
            invoiceFields: {
                email: {
                    required: helpers.withMessage('Please fill in your email address so we can send the registration to you', required),
                    email,
                },
                terms: { sameAsRawValue: helpers.withMessage('You have to agree with the terms and conditions', sameAs(true)) },
                privacy: { sameAsRawValue: helpers.withMessage('You have to agree with the privacy statement', sameAs(true)) },
            },
            advancedFields: {
                selectedSubcategory: {},
            },
        }

        if (!this.isNL(this.invoiceFields.countryId) && this.isEU(this.invoiceFields.countryId)) {
            validationRules.invoiceFields.vatNumber = {
                required: helpers.withMessage('Please enter your VAT number', required),
                isUnique: helpers.withMessage('This is not a valid VAT number', helpers.withAsync(this.isVatNumberValid)),
            }
        } else {
            validationRules.invoiceFields.vatNumber = {
                isUnique: helpers.withMessage(
                    "This is not a valid VAT number. If you don't have your VAT number on hand, you can leave this field blank.",
                    helpers.withAsync(this.isVatNumberValid),
                ),
            }
        }

        if (this.advancedFields.selectedCategory) {
            validationRules.advancedFields.selectedSubcategory = {
                required: helpers.withMessage('Please select a subcategory', required),
            }
        }

        return validationRules
    },
    beforeMount() {
        if (this.failedPayment) {
            this.paymentFailed = true
            this.selectedRegistration = this.failedPayment.registration
            this.invoiceFields.vatNumber = this.failedPayment.invoice_vat_number
            this.invoiceFields.firstName = this.failedPayment.invoice_first_name
            this.invoiceFields.lastName = this.failedPayment.invoice_last_name
            this.invoiceFields.street = this.failedPayment.invoice_street
            this.invoiceFields.housenumber = this.failedPayment.invoice_housenumber
            this.invoiceFields.zipcode = this.failedPayment.invoice_zipcode
            this.invoiceFields.city = this.failedPayment.invoice_city
            this.invoiceFields.state = this.failedPayment.invoice_state
            this.invoiceFields.email = this.failedPayment.invoice_email
            this.invoiceFields.company = this.failedPayment.invoice_company
            this.invoiceFields.countryId = this.failedPayment.invoice_country_id
            this.invoiceFields.terms = true
            this.invoiceFields.privacy = true
            this.showPaymentForm = true
            this.currentPaymentStep = 3
        }
    },
    mounted() {
        fetch('/api/countries')
            .then((response) => response.json())
            .then((countries) => {
                this.countries = countries
            })
    },
    methods: {
        toggleFieldClass(elementClassObj) {
            const element = document.getElementById(elementClassObj.elementId)
            Object.keys(elementClassObj.toggleClassesObject).forEach(function (key) {
                if (typeof elementClassObj.toggleClassesObject[key] !== 'boolean') {
                    return
                }

                if (elementClassObj.toggleClassesObject[key]) {
                    element.parentNode.classList.add('is-' + key)
                } else {
                    element.parentNode.classList.remove('is-' + key)
                }
            })
        },
        async isVatNumberValid(value) {
            if (!value) {
                return true
            }
            let isValid = false

            let url = `/api/check-vat-number/${value}`

            if (this.invoiceFields.countryId !== null) {
                url += '?countryCode=' + this.getCountryCode(this.invoiceFields.countryId)
            }

            await fetch(url)
                .then((result) => {
                    return result.json()
                })
                .then((json) => {
                    if (json.result == 'valid') {
                        isValid = true
                    }
                })
            return isValid
        },
        async search(type) {
            let params

            let showTooLittleInfo = false
            this.showTooLittleInfoBasic = false
            this.showTooLittleInfoAdvanced = false

            if (type == 'basic') {
                this.showAdvancedSearch = false

                params = new URLSearchParams([
                    ['type', 'basic'],
                    ['registrationCode', this.registrationCode],
                ])
                if (!this.registrationCode) {
                    showTooLittleInfo = true
                } else {
                    this.loading = true
                    this.axios
                        .get('search-register', {
                            params,
                        })
                        .then((response) => {
                            this.foundRegistrations = response.data.registrations
                            this.searched = true
                            this.loading = false
                            this.showAdvancedSearch = false
                            setTimeout(() => {
                                if (this.foundRegistrations.length > 0) {
                                    this.scrollToRegistrationsContainer()
                                }
                            }, 0)
                        })
                }
            } else if (type == 'advanced') {
                if (!this.advancedFields.firstRegistrant && !this.advancedFields.selectedDate && !this.advancedFields.selectedCategory) {
                    showTooLittleInfo = true
                }

                let isFormCorrect = await this.v$.advancedFields.$validate()

                if (!showTooLittleInfo && isFormCorrect) {
                    const paramValues = []
                    paramValues.push(['type', 'advanced'])

                    if (this.advancedFields.selectedCategory) {
                        paramValues.push(['category', this.advancedFields.selectedCategory])
                    }

                    if (this.advancedFields.selectedSubcategory) {
                        paramValues.push(['subcategory', this.advancedFields.selectedSubcategory])
                    }

                    if (this.advancedFields.firstRegistrant) {
                        paramValues.push(['firstRegistrant', this.advancedFields.firstRegistrant])
                    }

                    if (this.advancedFields.selectedDate) {
                        const exactDate =
                            this.advancedFields.selectedDate[0].getFullYear() +
                            '-' +
                            (this.advancedFields.selectedDate[0].getMonth() + 1) +
                            '-' +
                            this.advancedFields.selectedDate[0].getDate()
                        paramValues.push(['exactDate', exactDate])
                    }

                    params = new URLSearchParams(paramValues)
                    if (paramValues.length < 2) {
                        showTooLittleInfo = true
                    }

                    this.loading = true
                    this.axios
                        .get('search-register', {
                            params,
                        })
                        .then((response) => {
                            this.foundRegistrations = response.data.registrations
                            this.searched = true
                            this.loading = false
                            this.showAdvancedSearch = false
                            setTimeout(() => {
                                if (this.foundRegistrations.length > 0) {
                                    this.scrollToRegistrationsContainer()
                                }
                            }, 0)
                        })
                } else if (showTooLittleInfo) {
                    this.showTooLittleInfoAdvanced = true
                }
            }
        },
        scrollToRegistrationsContainer() {
            const reference = this.$refs.foundRegistrationsContainer
            const yOffset = -150
            const y = reference.getBoundingClientRect().top + window.pageYOffset + yOffset
            window.scrollTo({ top: y, behavior: 'smooth' })
        },
        openPaymentForm(event, registration) {
            event.preventDefault()
            this.selectedRegistration = registration
            this.showPaymentForm = true
        },
        getCountryNames(countryIds) {
            const countryNames = []
            const usedCountryIds = []
            countryIds.forEach((countryId) => {
                if (!usedCountryIds.includes(countryId)) {
                    usedCountryIds.push(countryId)
                    this.countries.every((country) => {
                        if (country.id === countryId) {
                            countryNames.push(country.country_name)
                            return false
                        }
                        return true
                    })
                }
            })

            return countryNames.join(', ')
        },
        getCopyrightOwnerNames(copyrightOwners) {
            const copyrightOwnerNames = []
            copyrightOwners.forEach((copyrightOwner) => {
                copyrightOwnerNames.push(copyrightOwner.full_name)
            })
            return copyrightOwnerNames.join(', ')
        },
        previousPaymentStep() {
            if (this.currentPaymentStep > 1) {
                this.currentPaymentStep -= 1
            }
        },
        async nextPaymentStep() {
            if (this.currentPaymentStep == 1) {
                this.currentPaymentStep = 2
            } else if (this.currentPaymentStep == 2) {
                let isFormCorrect = await this.v$.invoiceFields.$validate()
                if (isFormCorrect) {
                    this.currentPaymentStep = 3
                }
            } else if (this.currentPaymentStep == 3) {
                this.$refs.paymentForm.submit()
            }
        },
        showStateSelector(countryId) {
            let showStateSelector = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    showStateSelector = country.show_state_selector == 1
                    return false
                }
                return true
            })
            return showStateSelector
        },
        isEU(countryId) {
            let isEU = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    isEU = country.is_european_union == 1
                    return false
                }
                return true
            })
            return isEU
        },
        isNL(countryId) {
            let isNL = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    isNL = country.country_code == 'NL'
                    return false
                }
                return true
            })
            return isNL
        },
        getCountryCode(countryId) {
            let countryCode = null
            this.countries.every((country) => {
                if (country.id === countryId) {
                    countryCode = country.country_code
                    return false
                }
                return true
            })
            return countryCode
        },
    },
    computed: {
        subcategories() {
            let items
            if (this.advancedFields.selectedCategory) {
                this.categories.forEach((category) => {
                    if (category.id == this.advancedFields.selectedCategory) {
                        items = category.registration_subcategories
                        items.sort((a, b) => {
                            return a.name < b.name ? -1 : 1
                        })
                    }
                })
            } else {
                items = []
            }
            return items
        },
        vatReversed() {
            return (
                this.isEU(this.invoiceFields.countryId) &&
                !this.isNL(this.invoiceFields.countryId) &&
                this.invoiceFields.vatNumber.length > 0 &&
                this.invoiceFields.vatNumber.substring(0, 2).toUpperCase() !== 'NL'
            )
        },
        showVatNumber() {
            const show =
                this.invoiceFields.company &&
                this.invoiceFields.street &&
                this.invoiceFields.housenumber &&
                this.invoiceFields.zipcode &&
                this.invoiceFields.city &&
                this.invoiceFields.countryId &&
                this.isEU(this.invoiceFields.countryId) &&
                !this.isNL(this.invoiceFields.countryId)

            return show
        },
    },
    watch: {
        'invoiceFields.vatNumber': function (newValue, oldValue) {
            this.invoiceFields.vatNumber = newValue.replace(/[^a-zA-Z0-9]+/g, '').toUpperCase()
        },
    },
}

export { SearchForm }
