import useVuelidate from '@vuelidate/core'
import { required, email, helpers, sameAs } from '@vuelidate/validators'

const PasswordForm = {
    template: /*html*/ `

        <div class="registration-steps-container">
            <div class="registration-step-container">
                <form class="password-form" method="post" novalidate @submit="submitForm($event)">
                    <div class="section-header">
                        <h1>Reset your password</h1>
                    </div>

                    <div class="form-group">
                        <label for="newPassword1">Enter your new password</label>
                        <Password v-model="form.newPassword1" id="newPassword1" placeholder="Your new password" name="newPassword1" />
                        <small v-for="error of v$.form.newPassword1.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                    <div class="form-group">
                        <label for="newPassword2">Repeat your new password</label>
                        <Password :feedback="false" v-model="form.newPassword2" id="newPassword2" placeholder="Your new password again" name="newPassword2" />
                        <small v-for="error of v$.form.newPassword2.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                    <div class="checkout-button-container">
                        <button type="submit" class="button button-primary checkout-button">Opslaan</button>
                    </div>
                </form>
            </div>
        </div>
        `,
    setup() {
        return { v$: useVuelidate() }
    },
    props: ['formErrors'],
    data: function () {
        return {
            form: {
                newPassword1: '',
                newPassword2: '',
            },
        }
    },
    validations() {
        let validationRules = {
            form: {
                newPassword1: {
                    required,
                    //sameAsRawValue: helpers.withMessage('You have to agree with the terms and conditions', sameAs(true))
                },
                newPassword2: {
                    required,
                    sameAs: helpers.withMessage('Please enter the same password as above', sameAs(this.form.newPassword1)),
                },
            },
        }

        return validationRules
    },
    methods: {
        async submitForm(event) {
            const valid = await this.v$.$validate()

            if (!valid) {
                event.preventDefault()
            }
        },
    },
}

export { PasswordForm }
