const Users = {
    template: /*html*/ `
        <ConfirmPopup></ConfirmPopup>
        <div class="users-container">
            <DataTable :value="users" responsiveLayout="scroll" showGridlines :rowHover="true">
                <Column style="width: 30%" header="Name">
                    <template #body="slotProps">
                        <span :class="{'strikethrough': slotProps.data.status == 'deleted'}">
                            {{slotProps.data.first_name}} {{slotProps.data.last_name}}
                        </span>
                    </template>
                </Column>
                <Column style="width: 30%" header="Email">
                    <template #body="slotProps">
                        <span :class="{'strikethrough': slotProps.data.status == 'deleted'}">
                            {{slotProps.data.email}}
                        </span>                
                    </template>
                </Column>
                <Column style="width: 15%" header="Role">
                    <template #body="slotProps">
                        <span :class="{'strikethrough': slotProps.data.status == 'deleted'}" v-if="slotProps.data.role == 'admin'">Administrator</span>
                        <span :class="{'strikethrough': slotProps.data.status == 'deleted'}" v-if="slotProps.data.role == 'user'">User</span>
                    </template>
                </Column>
                <Column style="width: 15%" header="Status">
                    <template #body="slotProps">
                        <span :class="{'strikethrough': slotProps.data.status == 'deleted'}" v-if="slotProps.data.status == 'deleted'">Deleted</span>
                        <span v-if="slotProps.data.status == 'active'">Active</span>
                        <span v-if="slotProps.data.status == 'invited'">Invited</span>
                    </template>
                </Column>                
                <Column style="width: 10%" class="table-actions">
                    <template #body="slotProps">
                        <a :href="'dashboard/users/edit/' + slotProps.data.id"><i class="pi pi-pencil"></i></a>
                        <a :href="'dashboard/users/delete/' + slotProps.data.id" @click="deleteUser($event, slotProps.data.id)"><i class="pi pi-trash"></i></a>                            
                    </template>
                </Column>
            </DataTable>
        </div>

        <form ref="removeUserForm" action="dashboard/users/delete" method="post">
            <input type="hidden" name="userId" :value="removeUserId">
        </form>
        `,
    props: ['users'],
    data: function () {
        return {
            removeUserId: null,
        }
    },
    methods: {
        deleteUser(event, userId) {
            event.preventDefault()

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to remove this user?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.removeUserId = userId
                    setTimeout(() => {
                        this.$refs.removeUserForm.submit()
                    }, 0)
                },
            })
        },
    },
}

export { Users }
