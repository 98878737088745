import useVuelidate from '@vuelidate/core'
import { required, email, helpers, sameAs } from '@vuelidate/validators'

const RegisterForm = {
    template: /*html*/ `

        <div class="registration-introduction">
            Professional legal representatives, such as lawyers and trademark attorneys based in EU, may apply for an account on Copyrights Register. Upon receipt of your details, we will review your request. Upon confirmation of your approval, you, you will have full access to The Copyrights Register.
        </div>

        <div class="registration-steps-container">
            <div class="registration-step-container">
                <div class="step-header">
                    <h3>Please enter your information</h3>
                </div>

                <div class="step-content">
                    
                    <div class="form-group">
                        <h5>Information about your lawfirm</h5>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label for="firmName">Firm name *</label>
                                    <InputText id="firmName" :class="{'p-invalid': v$.fields.company.$error}" placeholder="Firm name *" v-model="fields.company" />
                                </div>
                                <small v-for="error of v$.fields.company.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>

                        <div class="grid p-fluid">
                            <div class="col-12 md:col-9">
                                <div class="inputgroup">
                                    <label for="streetName">Street name *</label>
                                    <InputText id="streetName" :class="{'p-invalid': v$.fields.street.$error}" placeholder="Street name *" v-model="fields.street"/>
                                </div>
                                <small v-for="error of v$.fields.street.$errors" class="p-error">{{error.$message}}</small>
                            </div>

                            <div class="col-12 md:col-3">
                                <div class="inputgroup">
                                    <label for="houseNumber">Number *</label>
                                    <InputText id="houseNumber" :class="{'p-invalid': v$.fields.housenumber.$error}" placeholder="Number *" v-model="fields.housenumber" />
                                </div>
                                <small v-for="error of v$.fields.housenumber.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-4">
                                <div class="inputgroup">
                                    <label for="zipcode">Zipcode *</label>
                                    <InputText id="zipcode" :class="{'p-invalid': v$.fields.zipcode.$error}" placeholder="Zipcode *" v-model="fields.zipcode" />
                                </div>
                                <small v-for="error of v$.fields.zipcode.$errors" class="p-error">{{error.$message}}</small>
                            </div>

                            <div class="col-12 md:col-8">
                                <div class="inputgroup">
                                    <label for="city">City *</label>
                                    <InputText id="city" :class="{'p-invalid': v$.fields.city.$error}" placeholder="City *" v-model="fields.city" />
                                </div>
                                <small v-for="error of v$.fields.city.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>

                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label for="country">Country *</label>
                                    <Dropdown id="country" :class="{'p-invalid': v$.fields.countryId.$error}" v-model="fields.countryId" optionValue="id" optionLabel="country_name" :options="countries" placeholder="Choose country *" />
                                </div>
                                <small v-for="error of v$.fields.countryId.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>

                        <div class="grid p-fluid">
                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="cocNumber">CoC number *</label>
                                    <InputText id="cocNumber" :class="{'p-invalid': v$.fields.cocNumber.$error}" placeholder="COC number *" v-model="fields.cocNumber" />
                                </div>
                                <small v-for="error of v$.fields.cocNumber.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="vatNumber">VAT number</label>
                                    <InputText id="vatNumber" :class="{'p-invalid': v$.fields.vatNumber.$error}" placeholder="VAT number" v-model="fields.vatNumber" />
                                    <p class="pt-0 mt-1 mb-0">VAT number is not required, but if you do enter your VAT number, VAT will be reversed.</p>
                                </div>
                                <small v-for="error of v$.fields.vatNumber.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>

                    </div>

                    <div class="form-group">
                        <h5>Your information</h5>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="firstName">First name *</label>
                                    <InputText id="firstName" :class="{'p-invalid': v$.fields.firstName.$error}" placeholder="First name *" v-model="fields.firstName"/>
                                </div>
                                <small v-for="error of v$.fields.firstName.$errors" class="p-error">{{error.$message}}</small>
                            </div>

                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="lastName">Last name *</label>
                                    <InputText id="lastName" :class="{'p-invalid': v$.fields.lastName.$error}" placeholder="Last name *" v-model="fields.lastName" />
                                </div>
                                <small v-for="error of v$.fields.lastName.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>                        
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label for="phonenumber">Phonenumber *</label>
                                    <InputText id="phonenumber" :class="{'p-invalid': v$.fields.telephone.$error}" placeholder="Phonenumber *" v-model="fields.telephone" />
                                </div>
                                <small v-for="error of v$.fields.telephone.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <h5>Account</h5>                        
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="emailAddress">Email address *</label>
                                    <InputText id="emailAddress" :class="{'p-invalid': v$.fields.email.$error}" placeholder="Email address *" v-model="fields.email" />
                                </div>
                                <small v-for="error of v$.fields.email.$errors" class="p-error">{{error.$message}}</small>
                            </div>

                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="password">Password *</label>
                                    <Password id="password" :class="{'p-invalid': v$.fields.password.$error}" placeholder="Choose your password *" v-model="fields.password" :toggleMask="true" />
                                </div>
                                <small v-for="error of v$.fields.password.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group">
                        <h5>Terms and conditions</h5>                        
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="p-inputgroup">
                                    <Checkbox id="terms" :binary="true" :class="{'p-invalid': v$.fields.terms.$error}" v-model="fields.terms" />
                                    <label class="checkbox-label" for="terms">I agree with the <a href="terms-and-conditions" target="_blank">terms and conditions</a></label>
                                </div>
                                <small v-for="error of v$.fields.terms.$errors" class="p-error">{{error.$message}}</small>
                            </div>

                            <div class="col-12">
                                <div class="p-inputgroup">
                                    <Checkbox id="privacy" :binary="true" :class="{'p-invalid': v$.fields.privacy.$error}" v-model="fields.privacy" />
                                    <label class="checkbox-label" for="privacy">I agree with the <a href="privacy-policy" target="_blank">privacy policy</a></label>
                                </div>
                                <small v-for="error of v$.fields.privacy.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="registration-buttons-container">
            <button class="button button-primary" @click="saveRegistration">Finish registration&nbsp;&nbsp;<i class="pi pi-chevron-right"></i></button>
        </div>

        <form ref="registerForm" action="register" method="post">
            <input v-if="lawfirm" type="hidden" name="invitationCode" :value="invitationCode">
            <input type="hidden" name="cocNumber" :value="fields.cocNumber">
            <input type="hidden" name="vatNumber" :value="fields.vatNumber">
            <input type="hidden" name="firstName" :value="fields.firstName">
            <input type="hidden" name="lastName" :value="fields.lastName">
            <input type="hidden" name="street" :value="fields.street">
            <input type="hidden" name="housenumber" :value="fields.housenumber">
            <input type="hidden" name="zipcode" :value="fields.zipcode">
            <input type="hidden" name="city" :value="fields.city">
            <input type="hidden" name="email" :value="fields.email">
            <input type="hidden" name="telephone" :value="fields.telephone">
            <input type="hidden" name="countryId" :value="fields.countryId">
            <input type="hidden" name="company" :value="fields.company">
            <input type="hidden" name="password" :value="fields.password">
        </form>

        `,
    props: ['lawfirm', 'invitationCode'],
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            fields: {
                cocNumber: this.lawfirm ? this.lawfirm.coc_number : null,
                firstName: this.lawfirm && this.lawfirm.admin ? this.lawfirm.admin.first_name : null,
                lastName: this.lawfirm && this.lawfirm.admin ? this.lawfirm.admin.last_name : null,
                street: this.lawfirm ? this.lawfirm.street : null,
                housenumber: this.lawfirm ? this.lawfirm.housenumber : null,
                zipcode: this.lawfirm ? this.lawfirm.zipcode : null,
                city: this.lawfirm ? this.lawfirm.city : null,
                email: this.lawfirm ? this.lawfirm.email : null,
                telephone: this.lawfirm ? this.lawfirm.telephone : null,
                company: this.lawfirm ? this.lawfirm.firm_name : null,
                countryId: this.lawfirm ? this.lawfirm.country_id : null,
                vatNumber: this.lawfirm ? this.lawfirm.vat_number : null,
                password: null,
                terms: null,
                privacy: null,
            },
            countries: [],
            initialEmailAddress: null,
        }
    },
    validations() {
        let validationRules = {
            fields: {
                company: { required },
                cocNumber: { required },
                street: { required },
                housenumber: { required },
                zipcode: { required },
                city: { required },
                email: {
                    required,
                    email,
                    isUnique: helpers.withMessage('This email address is already registered in our database', helpers.withAsync(this.isEmailTaken)),
                },
                firstName: { required },
                lastName: { required },
                telephone: { required },
                countryId: { required },
                password: {
                    required: helpers.withMessage('Please enter a password', required),
                },
                terms: {
                    sameAsRawValue: helpers.withMessage('You have to agree with the terms and conditions', sameAs(true)),
                },
                privacy: {
                    sameAsRawValue: helpers.withMessage('You have to agree with the privacy statement', sameAs(true)),
                },
            },
        }

        validationRules.fields.vatNumber = {
            isUnique: helpers.withMessage(
                "This is not a valid VAT number. If you don't have your VAT number on hand, you can leave this field blank, but you will be charged VAT.",
                helpers.withAsync(this.isVatNumberValid),
            ),
        }

        return validationRules
    },
    mounted() {
        fetch('/api/countries?exclude=US,UM')
            .then((response) => response.json())
            .then((countries) => {
                this.countries = countries
            })

        if (this.lawfirm && this.lawfirm.email) {
            this.initialEmailAddress = this.lawfirm.email
        }
    },
    methods: {
        async saveRegistration() {
            const valid = await this.v$.$validate()

            if (valid) {
                this.$refs.registerForm.submit()
            }
        },
        async isEmailTaken(value) {
            if (!value) {
                return true
            }
            let isTaken = false

            let url = `/api/check-email/${value}`
            if (this.initialEmailAddress !== null) {
                //url += '?exclude=' + this.initialEmailAddress
            }
            await fetch(url)
                .then((result) => {
                    return result.json()
                })
                .then((json) => {
                    if (json.result == 'unavailable') {
                        isTaken = true
                    }
                })

            return !isTaken
        },
        async isVatNumberValid(value) {
            if (!value) {
                return true
            }
            let isValid = false

            let url = `/api/check-vat-number/${value}`

            if (this.fields.countryId !== null) {
                url += '?countryCode=' + this.getCountryCode(this.fields.countryId)
            }

            await fetch(url)
                .then((result) => {
                    return result.json()
                })
                .then((json) => {
                    if (json.result == 'valid') {
                        isValid = true
                    }
                })
            return isValid
        },
        isNL(countryId) {
            let isNL = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    isNL = country.country_code == 'NL'
                    return false
                }
                return true
            })
            return isNL
        },
        getCountryCode(countryId) {
            let countryCode = null
            this.countries.every((country) => {
                if (country.id === countryId) {
                    countryCode = country.country_code
                    return false
                }
                return true
            })
            return countryCode
        },
    },
    computed: {
        fullName() {
            if (this.fields.firstName && this.fields.lastName) {
                return this.fields.firstName.trim() + ' ' + this.fields.lastName.trim()
            } else {
                return ''
            }
        },
    },
}

export { RegisterForm }
