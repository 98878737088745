const PaymentForm = {
    template: /*html*/ `
        <form class="payment-form" method="post" id="mcForm" @submit="submitForm($event)" action="dashboard/payment">
            <div v-if="registration.lawfirm.free_registrations == 0 || (registration.lawfirm.free_registrations == 1 && period == 10)" class="payment-method-selector">
                <div v-for="paymentMethod in paymentMethods" class="payment-method-container" :class="{'selected' : selectedPaymentMethod == paymentMethod.id, 'no-arrow': paymentMethod.id != 'ideal'}">
                    <label class="radio-container" :class="{'selected' : selectedPaymentMethod == paymentMethod.id}" @click="selectedPaymentMethod = paymentMethod.id">
                        <img :src="paymentMethod.image" :alt="'Pay with ' + paymentMethod.name">{{paymentMethod.name}}
                        <input type="radio" :checked="selectedPaymentMethod == paymentMethod.id" name="paymentMethod" :value="paymentMethod.id">
                        <span class="checkmark"></span>
                    </label>

                    <div v-if="paymentMethod.id == 'ideal'" class="payment-method-content">
                        <Dropdown v-model="selectedIssuer" :options="issuers" optionLabel="name" placeholder="Choose your bank">
                            <template #option="issuer">
                                <div class="p-dropdown-issuer-option">
                                    <img :alt="issuer.option.name" :src="issuer.option.image" />
                                    <span>{{issuer.option.name}}</span>
                                </div>
                            </template>

                            <template #value="issuer">
                                <div class="p-dropdown-issuer-value" v-if="issuer.value">
                                    <img :alt="issuer.value.name" :src="issuer.value.image" />
                                    <span>{{issuer.value.name}}</span>
                                </div>
                                <span v-else>
                                    {{issuer.placeholder}}
                                </span>
                            </template>

                        </Dropdown>
                        <input v-if="selectedIssuer" type="hidden" name="issuer" :value="selectedIssuer.id">
                    </div>

                </div>
            </div>
            
            <input type="hidden" name="registrationPeriod" :value="period">
            <input type="hidden" name="registrationCode" :value="registration.registration_code">
        </form>
        `,
    data: function () {
        return {
            selectedPaymentMethod: 'ideal',
            selectedIssuer: null,
            mollie: null,
        }
    },
    props: ['registration', 'paymentMethods', 'issuers', 'submit', 'period'],
    mounted() {},
    watch: {
        submit() {
            if (this.submit == true) {
                this.doSubmit()
            }
        },
    },
    methods: {
        toggleFieldClass(elementClassObj) {
            const element = document.getElementById(elementClassObj.elementId)
            Object.keys(elementClassObj.toggleClassesObject).forEach(function (key) {
                if (typeof elementClassObj.toggleClassesObject[key] !== 'boolean') {
                    return
                }

                if (elementClassObj.toggleClassesObject[key]) {
                    element.parentNode.classList.add('is-' + key)
                } else {
                    element.parentNode.classList.remove('is-' + key)
                }
            })
        },
        disableForm() {
            //submitButton.disabled = true;
        },
        enableForm() {
            //submitButton.disabled = false;
        },
        submitForm(event) {
            event.preventDefault()
            this.doSubmit()
        },
        doSubmit() {
            if (this.paymentMethod === 'creditcard') {
                const form = document.getElementById('mcForm')
                const formError = document.getElementById('form-error')

                this.disableForm()

                formError.textContent = ''

                this.mollie.createToken().then(function (result) {
                    const token = result.token
                    const error = result.error

                    if (error) {
                        this.enableForm()
                        formError.textContent = error.message
                        return
                    }

                    const tokenInput = document.createElement('input')
                    tokenInput.setAttribute('name', 'token')
                    tokenInput.setAttribute('type', 'hidden')
                    tokenInput.setAttribute('value', token)

                    form.appendChild(tokenInput)
                    form.submit()
                })
            } else {
                const form = document.getElementById('mcForm')
                form.submit()
            }
        },
    },
}

export { PaymentForm }
