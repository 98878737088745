import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const CopyrightRegistrationForm = {
    template: /*html*/ `

        <ConfirmPopup></ConfirmPopup>

        <div v-if="currentStep == 1 && registration.status !== 'preregistration-done'" class="registration-introduction">
            Know before you go; what is required to create your registration:
            <ul>
                <li>Full name and address details of rightful owner(s);</li>
                <li>Digital file of the work;</li>
                <li>Description of the original elements of your work.</li>
            </ul>
            During the registration process your progress will be saved for future use.
        </div>

        <div v-if="type == 'client' && registration.status !== 'preregistration-done'" class="registration-introduction">
            If you encounter any difficulties filling out this form, don't hesitate to contact your IP Representative. The contact information of your IP Representative is:<br>
            <br>
            <strong>{{ lawfirm.firm_name }}</strong><br>
            Email: <a :href="'mailto:' + lawfirm.email"><strong>{{ lawfirm.email }}</strong></a><br>
            <template v-if="lawfirm.telephone">Telephone: <strong>{{ lawfirm.telephone }}</strong><br></template>
        </div>

        <div v-if="readonly == false && (type == 'representative' || registration.status != 'preregistration-done')" class="steps-container">
            <div class="step-items">
                <ul class="tablist">
                    <li v-for="step in steps" class="step-item" :class="{'current-step': currentStep == step.id}">
                        <span class="step-link" role="presentation">
                            <span v-if="step.id >= currentStep" class="step-number">{{ step.id }}</span>
                            <span v-if="step.id < currentStep" class="step-done"><i class="pi pi-check"></i></span>
                            <span class="step-title">{{ step.label }}</span>
                        </span>
                    </li>
                </ul>
            </div>            
        </div>

        <div v-if="type == 'client' && registration.status == 'preregistration-done'" class="registration-steps-container">
            <p v-if="registration.forced_taken_over == 0">Thank you! Your registration has been sent to <strong>{{ lawfirm.firm_name }}</strong>. After processing your registration, <strong>{{ lawfirm.firm_name }}</strong> will get back to you.</p>
            <p v-if="registration.forced_taken_over == 1">Your registration has been taken over by your IP Representative: <strong>{{ lawfirm.firm_name }}</strong>. After processing your registration, <strong>{{ lawfirm.firm_name }}</strong> will get back to you.</p>
        </div>

        <div v-if="type == 'representative' || registration.status != 'preregistration-done'" class="registration-steps-container">

            <div v-if="currentStep == 1" class="registration-step-container">
                <div class="step-header">
                    <h3>Current copyright owner or exclusive licensee</h3>
                </div>
                <div class="step-content">

                    <div class="copyright-owner-container" v-for="(copyrightOwner, index) in steps[1].fields.copyrightOwners">
                        <div class="copyright-owner-header">
                            <h4>Current owner of rights #{{ index + 1 }}</h4>
                            <a href="#" @click="removeCopyrightOwner($event, index)"><i class="pi pi-trash"></i>&nbsp;&nbsp;Remove</a>
                        </div>

                        <div class="form-group">
                            <div class="grid p-fluid">
                                
                                <div class="col-12">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-company">Company name (optional)</label>
                                        <InputText id="copyright-owner-company" v-model="steps[1].fields.copyrightOwners[index].company" />
                                    </div>
                                </div>
                                
                                <div class="col-12" v-if="steps[1].fields.copyrightOwners[index].company">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-coc">Chamber of Commerce Number *</label>
                                        <InputText id="copyright-owner-coc" :class="{'p-invalid': v$.steps[1].fields.copyrightOwners[index].cocNumber.$error}" v-model="steps[1].fields.copyrightOwners[index].cocNumber" />
                                    </div>
                                    <small v-for="error of v$.steps[1].fields.copyrightOwners[index].cocNumber.$errors" class="p-error">{{error.$message}}</small>
                                </div>

                                <div class="col-12 md:col-6">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-firstname">First given name *</label>
                                        <InputText id="copyright-owner-firstname" :class="{'p-invalid': v$.steps[1].fields.copyrightOwners[index].firstName.$error}" v-model="steps[1].fields.copyrightOwners[index].firstName" />
                                    </div>
                                    <small v-for="error of v$.steps[1].fields.copyrightOwners[index].firstName.$errors" class="p-error">{{error.$message}}</small>
                                </div>

                                <div class="col-12 md:col-6">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-lastname">Last name *</label>
                                        <InputText id="copyright-owner-lastname" :class="{'p-invalid': v$.steps[1].fields.copyrightOwners[index].lastName.$error}" v-model="steps[1].fields.copyrightOwners[index].lastName" />
                                    </div>
                                    <small v-for="error of v$.steps[1].fields.copyrightOwners[index].lastName.$errors" class="p-error">{{error.$message}}</small>
                                </div>

                                <div class="col-12 md:col-6">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-birthdate">Birth date *</label>
                                        <div class="grid p-fluid">
                                            <div class="col-12 md:col-4">
                                                <Dropdown id="copyright-owner-birthdate-day" v-model="steps[1].fields.copyrightOwners[index].birthdate.day" placeholder="Day" optionValue="code" optionLabel="label" :options="days"></Dropdown>
                                                <small v-for="error of v$.steps[1].fields.copyrightOwners[index].birthdate.day.$errors" class="p-error">{{error.$message}}</small>
                                            </div>
                                            <div class="col-12 md:col-4">
                                                <Dropdown id="copyright-owner-birthdate-month" v-model="steps[1].fields.copyrightOwners[index].birthdate.month" placeholder="Month" optionValue="code" optionLabel="label" :options="months"></Dropdown>
                                                <small v-for="error of v$.steps[1].fields.copyrightOwners[index].birthdate.month.$errors" class="p-error">{{error.$message}}</small>
                                            </div>
                                            <div class="col-12 md:col-4">
                                                <Dropdown id="copyright-owner-birthdate-year" v-model="steps[1].fields.copyrightOwners[index].birthdate.year" placeholder="Year" optionValue="code" optionLabel="label" :options="years"></Dropdown>
                                                <small v-for="error of v$.steps[1].fields.copyrightOwners[index].birthdate.year.$errors" class="p-error">{{error.$message}}</small>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <p class="p-note">Please make sure you enter the first given name, last name and date of birth exactly as they appear in passport or identity card. A copyright registration may be invalidated in a court of law if inaccurate information is included.</p>

                                <div class="col-12 md:col-9">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-street">Street name</label>
                                        <InputText id="copyright-owner-street" v-model="steps[1].fields.copyrightOwners[index].street"/>
                                    </div>
                                </div>

                                <div class="col-12 md:col-3">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-housenumber">Housenumber</label>
                                        <InputText id="copyright-owner-housenumber" v-model="steps[1].fields.copyrightOwners[index].housenumber" />
                                    </div>
                                </div>

                                <div class="col-12 md:col-4">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-zipcode">Zipcode</label>
                                        <InputText id="copyright-owner-zipcode" v-model="steps[1].fields.copyrightOwners[index].zipcode" />
                                    </div>
                                </div>

                                <div class="col-12 md:col-8">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-city">City</label>
                                        <InputText id="copyright-owner-city" v-model="steps[1].fields.copyrightOwners[index].city" />
                                    </div>
                                </div>

                                <div v-if="showStateSelector(steps[1].fields.copyrightOwners[index].country_id)" class="col-12 md:col-6">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-state">State</label>
                                        <InputText id="copyright-owner-state" v-model="steps[1].fields.copyrightOwners[index].state" />
                                    </div>
                                </div>

                                <div class="col-12 md:col-6">
                                    <div class="inputgroup">
                                        <label for="copyright-owner-country">Country</label>
                                        <Dropdown id="copyright-owner-country" v-model="steps[1].fields.copyrightOwners[index].country_id" optionValue="id" optionLabel="country_name" :options="countries" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p v-if="steps[1].fields.copyrightOwners[index].country_id && !isEurope(steps[1].fields.copyrightOwners[index].country_id) && !['US', 'CA'].includes(steps[1].fields.copyrightOwners[index].country_id)" class="country-disclaimer">* Please note that in case copyright creator is non-European and the work involved is not protected in the country of origin, this could result that a claim bases on copyright protection could be dismissed in a court of law.</p> 
                    </div>

                    <button class="button button-default low-button" @click="addCopyrightOwner()"><i class="pi pi-plus"></i> Add another current owner of rights</button>
                </div>
            </div>

            <div v-if="currentStep == 2" class="registration-step-container">
                <div class="step-header">
                    <h3>Creator(s)</h3>
                </div>

                <div class="step-content">
                    <div class="substep-container">
                        <h4>Who are the creators of the work?</h4>

                        <div class="creator-container" v-for="(creator, index) in steps[2].fields.creators">
                            <div class="creator-header">
                                <h4>Creator #{{ index + 1 }}</h4>
                                <a href="#" @click="removeCreator($event, index)"><i class="pi pi-trash"></i> Remove</a>
                            </div>

                            <div class="form-group">

                                <div v-if="1==2" class="grid p-fluid">
                                    <div class="col pb-0">
                                        <div class="inputgroup inputgroup-checkbox">
                                            <RadioButton :id="'creator-type-company-' + index" name="creatorType" value="company" @click="changeCreatorType(index, 'company')" v-model="steps[2].fields.creators[index].type" />
                                            <label :for="'creator-type-company-' + index">Company</label>
                                        </div>
                                        <div class="inputgroup inputgroup-checkbox mb-0">
                                            <RadioButton :id="'creator-type-person-' + index" name="creatorType" value="person" @click="changeCreatorType(index, 'person')" v-model="steps[2].fields.creators[index].type" />
                                            <label :for="'creator-type-person-' + index" >Person</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="grid p-fluid" v-if="steps[2].fields.creators[index].type == 'company'">
                                    <div class="col-12">
                                        <div class="inputgroup">
                                            <label for="creator-firstname">Company *</label>
                                            <InputText id="creator-company" :class="{'p-invalid': v$.steps[2].fields.creators[index].company.$error}" v-model="steps[2].fields.creators[index].company" />
                                        </div>
                                        <small v-for="error of v$.steps[2].fields.creators[index].company.$errors" class="p-error">{{error.$message}}</small>
                                    </div>
                                </div>

                                <div class="grid p-fluid" v-if="steps[2].fields.creators[index].type == 'person'">
                                    <div class="col-12 md:col-6">
                                        <div class="inputgroup">
                                            <label for="creator-firstname">First name *</label>
                                            <InputText id="creator-firstname" :class="{'p-invalid': v$.steps[2].fields.creators[index].firstName.$error}" v-model="steps[2].fields.creators[index].firstName" />
                                        </div>
                                        <small v-for="error of v$.steps[2].fields.creators[index].firstName.$errors" class="p-error">{{error.$message}}</small>
                                    </div>

                                    <div class="col-12 md:col-6">
                                        <div class="inputgroup">
                                            <label for="creator-lastname">Last name *</label>
                                            <InputText id="creator-lastname" :class="{'p-invalid': v$.steps[2].fields.creators[index].lastName.$error}" v-model="steps[2].fields.creators[index].lastName" />
                                        </div>
                                        <small v-for="error of v$.steps[2].fields.creators[index].lastName.$errors" class="p-error">{{error.$message}}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="button button-default low-button" @click="addCreator()"><i class="pi pi-plus"></i> Add another creator</button>

                    </div>

                    <div v-if="creatorsThatAreNotCopyrightOwners.count > 0" class="substep-container">
                        <h4>When was the work transferred to the current owner(s) or rights?</h4>
                        <Calendar :class="{'p-invalid': v$.steps[2].fields.workTransferDate.$error}" placeholder="Choose date" v-model="steps[2].fields.workTransferDate" dateFormat="yy/mm/dd" :maxDate="new Date()" />
                        <small v-for="error of v$.steps[2].fields.workTransferDate.$errors" class="p-error">{{error.$message}}</small>

                        <div class="substep-subcontainer">
                            <h4>(optional) Upload deed of transfer.</h4>                            
                            
                            <FileUpload 
                                name="proof_transfer_date[]" 
                                mode="basic" 
                                :url="uploadFilesUrl" 
                                :show-cancel-button="false"
                                :maxFileSize="10000000"
                                :accept="'image/*,.doc,.docx,.pdf'"
                                :auto="true"
                                :multiple="false"
                                @before-send="beforeUpload" 
                                @upload="afterUpload"
                            />

                            <DataTable v-if="filesByType('proof_transfer_date').length > 0" :value="filesByType('proof_transfer_date')" responsiveLayout="scroll" showGridlines :rowHover="true">
                                <Column :style="'width: 25%; text-align: center;'">
                                    <template #body="slotProps">
                                        <img v-if="!['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension)" :src="'images/file-' + slotProps.data.extension + '.png'">
                                    </template>
                                </Column>     
                                <Column field="file_name" header="File name" :style="'width: 50%'"></Column>
                                <Column>
                                    <template #body="slotProps">
                                        <span style="display: block;"><i class="pi pi-trash"></i>&nbsp;&nbsp;<a href="#" @click="removeFile($event, slotProps.data.id)">Remove file</a></span>
                                        <span style="display: block;"><i class="pi pi-download"></i>&nbsp;&nbsp;<a href="#" @click="downloadFile($event, slotProps.data.id)">Download file</a></span>
                                    </template>
                                </Column>
                            </DataTable>
                            
                            <div class="p-note">For your convenience we provide you with a template which can be used to make a deed of transfer. You can download the template <a href="files/deed-of-transfer-WEIP.pdf" target="_blank">here</a>.</div>
                        </div>
                    </div>

                    <div class="substep-container" v-if="creatorsThatAreNotCopyrightOwners.count > 0">
                        <h4>Did {{ creatorsThatAreNotCopyrightOwners.names }} waive (as far as possible) {{ creatorsThatAreNotCopyrightOwners.count == 1 ? 'his/her' : 'their' }} moral rights?</h4>
                        <SelectButton :class="{'p-invalid': v$.steps[2].fields.moralRights.$error}" v-model="steps[2].fields.moralRights" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                        <small v-for="error of v$.steps[2].fields.moralRights.$errors" class="p-error">{{error.$message}}</small>
                        <p v-if="steps[2].fields.moralRights == 0" class="substep-disclaimer">
                            * Please note that the creator may object to:
                            <ul>
                                <li>Publication of the work without mention of creator's name</li>
                                <li>Derogatory treatment of the work</li>
                                <li>False attribution of the work.</li>
                            </ul>
                        </p>

                        <div v-if="steps[2].fields.moralRights == 1" class="substep-subcontainer">
                            <h4>(optional) Upload proof.</h4>                            
                            
                            <FileUpload 
                                name="proof_waive_moral_rights[]" 
                                mode="basic" 
                                :url="uploadFilesUrl" 
                                :show-cancel-button="false"
                                :maxFileSize="20000000"
                                :accept="'image/*,.doc,.docx,.pdf'"
                                :auto="true"
                                :multiple="false"
                                @before-send="beforeUpload" 
                                @upload="afterUpload"
                            />

                            <DataTable v-if="filesByType('proof_waive_moral_rights').length > 0" :value="filesByType('proof_waive_moral_rights')" responsiveLayout="scroll" showGridlines :rowHover="true">
                                <Column :style="'width: 25%; text-align: center;'">
                                    <template #body="slotProps">
                                        <img v-if="!['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension)" :src="'images/file-' + slotProps.data.extension + '.png'">
                                    </template>
                                </Column>     
                                <Column field="file_name" header="File name" :style="'width: 50%'"></Column>
                                <Column>
                                    <template #body="slotProps">
                                        <span style="display: block;"><i class="pi pi-trash"></i>&nbsp;&nbsp;<a href="#" @click="removeFile($event, slotProps.data.id)">Remove file</a></span>
                                        <span style="display: block;"><i class="pi pi-download"></i>&nbsp;&nbsp;<a href="#" @click="downloadFile($event, slotProps.data.id)">Download file</a></span>
                                    </template>
                                </Column>
                            </DataTable>
                            
                            <small v-if="fileErrors.proof_waive_moral_rights" class="p-error">You need to upload at least 1 file</small>

                            <div class="p-note">For your convenience we provide you with a template which can be used to make a deed of transfer. You can download the template <a href="files/deed-of-transfer-WEIP.pdf" target="_blank">here</a>.</div>
                        </div>

                    </div>

                    <div v-if="creatorsThatAreNotCopyrightOwners.count == 0" class="substep-container">
                        <h4>When was the work created?</h4>
                        <Calendar :class="{'p-invalid': v$.steps[2].fields.workCreationDate.$error}" placeholder="Choose date" v-model="steps[2].fields.workCreationDate" dateFormat="yy/mm/dd" :maxDate="new Date()" />
                        <small v-for="error of v$.steps[2].fields.workCreationDate.$errors" class="p-error">{{error.$message}}</small>

                        <div v-if="steps[2].fields.workCreationDate && !isToday(steps[2].fields.workCreationDate)" class="substep-subcontainer">
                            <h4>(optional) Upload proof (statement of creation).</h4>                            
                            
                            <FileUpload 
                                name="proof_creation_date[]" 
                                mode="basic" 
                                :url="uploadFilesUrl" 
                                :show-cancel-button="false"
                                :maxFileSize="20000000"
                                :accept="'image/*,.doc,.docx,.pdf'"
                                :auto="true"
                                :multiple="false"
                                @before-send="beforeUpload" 
                                @upload="afterUpload"
                            />
                            
                            <DataTable v-if="filesByType('proof_creation_date').length > 0" :value="filesByType('proof_creation_date')" responsiveLayout="scroll" showGridlines :rowHover="true">
                                <Column :style="'width: 25%; text-align: center;'">
                                    <template #body="slotProps">
                                        <img v-if="!['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension)" :src="'images/file-' + slotProps.data.extension + '.png'">
                                    </template>
                                </Column>                                 
                                <Column field="file_name" header="File name" :style="'width: 50%'"></Column>
                                <Column>
                                    <template #body="slotProps">
                                        <span style="display: block;"><i class="pi pi-trash"></i>&nbsp;&nbsp;<a href="#" @click="removeFile($event, slotProps.data.id)">Remove file</a></span>
                                        <span style="display: block;"><i class="pi pi-download"></i>&nbsp;&nbsp;<a href="#" @click="downloadFile($event, slotProps.data.id)">Download file</a></span>
                                    </template>
                                </Column>
                            </DataTable>

                            <small v-if="fileErrors.proof_creation_date" class="p-error">You need to upload at least 1 file</small>

                            <div class="p-note">For your convenience we provide you with a template which can be used to make a statement of creation. You can download the template <a href="files/statement-of-creation-WEIP.pdf" target="_blank">here</a>.</div>
                        </div>
                    </div>

                    <div v-if="creatorsThatAreNotCopyrightOwners.count == 0" class="substep-container">
                        <h4>Do you want to publish the creation date in the public register?</h4>
                        <SelectButton :class="{'p-invalid': v$.steps[2].fields.publishCreationDate.$error}" v-model="steps[2].fields.publishCreationDate" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                        <small v-for="error of v$.steps[2].fields.publishCreationDate.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                    <div v-if="creatorsThatAreNotCopyrightOwners.count > 0" class="substep-container">
                        <h4>Do you want to publish the transfer date in the public register?</h4>
                        <SelectButton :class="{'p-invalid': v$.steps[2].fields.publishTransferDate.$error}" v-model="steps[2].fields.publishTransferDate" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                        <small v-for="error of v$.steps[2].fields.publishTransferDate.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                </div>
            </div>

            <div v-if="currentStep == 3" class="registration-step-container">
                <div class="step-header">
                    <h3>Please choose the class that describes your work best</h3>
                </div>
                <div class="step-content">
                    <div class="substep-container">
                        <div class="md:two-columns">
                            <div v-for="category in categories" class="field-radiobutton">
                                <RadioButton :id="'category' + category.id" name="category" :value="category.id" v-model="steps[3].fields.selectedCategory" />
                                <label :for="'category' + category.id">{{category.code}}. {{category.name}}</label>
                            </div>
                        </div>
                        <small v-for="error of v$.steps[3].fields.selectedCategory.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                    <div v-if="Object.keys(subcategories).length" class="substep-container">
                        <h4>Please choose the subclass that describes your work best</h4>
                        <div v-for="subcategory in subcategories" class="field-radiobutton">
                            <RadioButton :id="'subcategory' + subcategory.id" name="subcategory" :value="subcategory.id" v-model="steps[3].fields.selectedSubcategory" />
                            <label :for="'subcategory' + subcategory.id">{{subcategory.code}}. {{subcategory.name}}</label>
                        </div>
                        <small v-for="error of v$.steps[3].fields.selectedSubcategory.$errors" class="p-error">{{error.$message}}</small>
                    </div>
                </div>
            </div>

            <div v-if="currentStep == 4" class="registration-step-container">
                <div class="step-header">
                    <h3>Scope of protection</h3>
                </div>
                <div class="step-content">
                    <div class="substep-container">
                        <h4>Does the work consist of a combination of original elements?</h4>
                        <SelectButton :class="{'p-invalid': v$.steps[4].fields.originalElements.$error}" v-model="steps[4].fields.originalElements" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                        <small v-for="error of v$.steps[4].fields.originalElements.$errors" class="p-error">{{error.$message}}</small>
                        <p v-if="steps[4].fields.originalElements == 1" class="p-note">Please note that you will be able to upload your work (multiple files) and describe additional original elements with regard to your work in the next step.</p>
                    </div>
                    <div v-if="steps[4].fields.originalElements == 1" class="substep-container">
                        <h4>Please describe the combination of original elements <span>(this part shall not be made available to public)</span></h4>
                        <Textarea :class="{'p-invalid': v$.steps[4].fields.originalElementsDescription.$error}" v-model="steps[4].fields.originalElementsDescription" :autoResize="true" rows="5" cols="30" />
                        <small v-for="error of v$.steps[4].fields.originalElementsDescription.$errors" class="p-error">{{error.$message}}</small>
                    </div>
                    <div v-if="steps[4].fields.originalElements == 1" class="substep-container">
                        <h4>How does the work differ from other existing works? <span>(this part shall not be made available to public)</span></h4>
                        <Textarea :class="{'p-invalid': v$.steps[4].fields.howDiffers.$error}" v-model="steps[4].fields.howDiffers" :autoResize="true" rows="5" cols="30" />
                        <small v-for="error of v$.steps[4].fields.howDiffers.$errors" class="p-error">{{error.$message}}</small>
                    </div>
                    <div v-if="steps[4].fields.originalElements == 0">
                        If your work does not contain original elements, your copyright cannot be registered.
                    </div>
                </div>
            </div>

            <div v-if="currentStep == 5" class="registration-step-container">
                <div class="step-header">
                    <h3>Your work</h3>
                </div>

                <div class="step-content">

                    <div class="substep-container">
                        <h4 v-if="workNameRequiredClassIds.includes(steps[3].fields.selectedCategory)">Name of the work</h4>
                        <h4 v-if="!workNameRequiredClassIds.includes(steps[3].fields.selectedCategory)">Name of the work <span>(optional)</span></h4>
                        <InputText v-model="steps[5].fields.workName" />
                        <small v-for="error of v$.steps[5].fields.workName.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                    <div class="substep-container">
                        <h4 v-if="!workNameRequiredClassIds.includes(steps[3].fields.selectedCategory)">Please upload a main image of your work, for publication in the register</h4>
                        <h4 v-if="workNameRequiredClassIds.includes(steps[3].fields.selectedCategory)">(optional) Please upload a main image of your work, for publication in the register</h4>
                        <FileUpload 
                            v-if="filesByType('main_image').length < 1"
                            name="main_image[]" 
                            mode="basic" 
                            :url="uploadFilesUrl" 
                            :show-cancel-button="false"
                            :maxFileSize="20000000"
                            :accept="'image/*'"
                            :auto="true"
                            :multiple="false"
                            :fileLimit="1 - filesByType('main_image').length"
                            @before-send="beforeUpload" 
                            @upload="afterUpload"
                        />

                        <p class="maximum-files-message" v-if="filesByType('main_image').length == 1">You have reached the maximum of 1 file.</p>

                        <DataTable v-if="filesByType('main_image').length > 0" :value="filesByType('main_image')" responsiveLayout="scroll" showGridlines :rowHover="true">
                            <Column :style="'width: 25%'">
                                <template #body="slotProps">
                                    <img v-if="type == 'representative'" class="w-100" :src="'/image-proxy/' + slotProps.data.id + '?w=400'" onerror="this.src='/images/registration-placeholder.png'">
                                    <img v-if="type == 'client'" class="w-100" :src="'/image-proxy/' + slotProps.data.id + '?w=400&clientUrl=' + lawfirm.client_url + '&registrationHash=' + registration.registration_hash" onerror="this.src='/images/registration-placeholder.png'">                                    
                                </template>
                            </Column>                        
                            <Column header="File name" :style="'width: 50%'">
                                <template #body="slotProps">
                                    <div class="file-name-container">{{ slotProps.data.file_name }}</div>
                                    <Textarea class="file-description" placeholder="(optional) Describe the combination of original elements (will not be made public)" v-model="fileDescriptions[slotProps.data.id]" />
                                </template>
                            </Column>
                            <Column :style="'width: 25%'">
                                <template #body="slotProps">
                                    <span style="display: block;"><i class="pi pi-trash"></i>&nbsp;&nbsp;<a href="#" @click="removeFile($event, slotProps.data.id)">Remove file</a></span>
                                    <span style="display: block;"><i class="pi pi-download"></i>&nbsp;&nbsp;<a href="#" @click="downloadFile($event, slotProps.data.id)">Download file</a></span>
                                </template>
                            </Column>
                        </DataTable>

                        <small v-if="fileErrors.main_image" class="p-error">You need to upload at least 1 file</small>
                    </div>

                    <div class="substep-container">
                        <h4>Upload more images and files here. You can upload a maximum of 14 files.</h4>

                        <FileUpload 
                            v-if="filesByType('work').length < 14"
                            name="work[]" 
                            mode="basic" 
                            :url="uploadFilesUrl" 
                            :show-cancel-button="false"
                            :maxFileSize="20000000"
                            :accept="'image/*,.doc,.docx,.pdf'"
                            :auto="true"
                            :multiple="false"
                            @before-send="beforeUpload" 
                            @upload="afterUpload"
                        />

                        <p class="maximum-files-message" v-if="filesByType('work').length == 14">You have reached the maximum of 14 files.</p>

                        <DataTable v-if="filesByType('work').length > 0" :value="filesByType('work')" responsiveLayout="scroll" showGridlines :rowHover="true">
                            <Column :style="'width: 25%; text-align: center;'">
                                <template #body="slotProps">
                                    <img v-if="['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension) && type == 'representative'" class="w-100" :src="'/image-proxy/' + slotProps.data.id + '?w=400'" onerror="this.src='/images/registration-placeholder.png'">
                                    <img v-if="['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension) && type == 'client'" class="w-100" :src="'/image-proxy/' + slotProps.data.id + '?w=400&clientUrl=' + lawfirm.client_registration_url" onerror="this.src='/images/registration-placeholder.png'">
                                    <img v-if="!['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension)" :src="'images/file-' + slotProps.data.extension + '.png'">
                                </template>
                            </Column>                        
                            <Column header="File name" :style="'width: 50%'">
                                <template #body="slotProps">
                                    <div class="file-name-container">{{ slotProps.data.file_name }}</div>
                                    <Textarea class="file-description" placeholder="(optional) Describe the combination of original elements (will not be made public)" v-model="fileDescriptions[slotProps.data.id]" />
                                </template>
                            </Column>
                            <Column :style="'width: 25%'">
                                <template #body="slotProps">
                                    <span style="display: block;"><i class="pi pi-trash"></i>&nbsp;&nbsp;<a href="#" @click="removeFile($event, slotProps.data.id)">Remove file</a></span>
                                    <span style="display: block;"><i class="pi pi-download"></i>&nbsp;&nbsp;<a href="#" @click="downloadFile($event, slotProps.data.id)">Download file</a></span>
                            </template>
                            </Column>
                        </DataTable>
                        <small v-if="fileErrors.work" class="p-error">You need to upload at least 1 file</small>
                    </div>

                    <div v-if="filesByType('main_image').length > 0 || filesByType('work').length > 0" class="substep-container">
                        <h4>The images may be shown in the Copyrights Register.</h4>
                        <SelectButton v-model="steps[5].fields.imagesMayBeShown" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                    </div>

                    <div v-if="1==2" class="substep-container">
                        <h4>When were the copyrights with regard to the work obtained?</h4>
                        <Calendar :class="{'p-invalid': v$.steps[5].fields.copyrightDate.$error}" id="testlabel" v-model="steps[5].fields.copyrightDate" placeholder="Choose date" dateFormat="yy/mm/dd" :maxDate="new Date()"/>
                        <small v-for="error of v$.steps[5].fields.copyrightDate.$errors" class="p-error">{{error.$message}}</small>

                        <div v-if="!isToday(steps[5].fields.copyrightDate)" class="substep-subcontainer">
                            <h4>Upload proof (for example: notary act). You can upload a maximum of 2 files.</h4>                            

                            <FileUpload 
                                v-if="filesByType('proof_notary_act').length < 2"
                                name="proof_notary_act[]" 
                                mode="basic" 
                                :url="uploadFilesUrl" 
                                :show-cancel-button="false"
                                :maxFileSize="10000000"
                                :accept="'image/*,.doc,.docx,.pdf'"
                                :auto="true"
                                :multiple="true"
                                :fileLimit="2 - filesByType('proof_notary_act').length"                            
                                @before-send="beforeUpload" 
                                @upload="afterUpload"
                            />

                            <p class="maximum-files-message" v-if="filesByType('proof_notary_act').length == 2">You have reached the maximum of 2 files.</p>

                            <DataTable v-if="filesByType('proof_notary_act').length > 0" :value="filesByType('proof_notary_act')" responsiveLayout="scroll" showGridlines :rowHover="true">
                                <Column :style="'width: 25%; text-align: center;'">
                                    <template #body="slotProps">
                                        <img v-if="!['jpg', 'png', 'gif', 'bmp', 'jpeg', 'webp'].includes(slotProps.data.extension)" :src="'images/file-' + slotProps.data.extension + '.png'">
                                    </template>
                                </Column>                            
                                <Column field="file_name" header="File name" :style="'width: 50%'"></Column>
                                <Column>
                                    <template #body="slotProps">
                                        <span style="display: block;"><i class="pi pi-trash"></i>&nbsp;&nbsp;<a href="#" @click="removeFile($event, slotProps.data.id)">Remove file</a></span>
                                        <span style="display: block;"><i class="pi pi-download"></i>&nbsp;&nbsp;<a href="#" @click="downloadFile($event, slotProps.data.id)">Download file</a></span>    
                                    </template>
                                </Column>
                            </DataTable>

                            <small v-if="fileErrors.proof_notary_act" class="p-error">You need to upload at least 1 file</small>
                        </div>
                    </div>

                    <div class="substep-container">
                        <h4>Do you want to publish the registration date of your work in the public Copyrights Register?</h4>
                        <SelectButton :class="{'p-invalid': v$.steps[5].fields.publishRegistrationDate.$error}" v-model="steps[5].fields.publishRegistrationDate" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                        <small v-for="error of v$.steps[5].fields.publishRegistrationDate.$errors" class="p-error">{{error.$message}}</small>
                    </div>

                    <div class="substep-container">
                        <h4>Do you want to publish the name of your work in the Copyrights Register?</h4>
                        <SelectButton :class="{'p-invalid': v$.steps[5].fields.publishWorkName.$error}" v-model="steps[5].fields.publishWorkName" :options="[{value: 1, label: 'Yes'}, {value: 0, label: 'No'}]" optionValue="value" optionLabel="label" />
                        <small v-for="error of v$.steps[5].fields.publishWorkName.$errors" class="p-error">{{error.$message}}</small>

                        <small v-if="steps[5].fields.publishRegistrationDate == 1 && steps[5].fields.publishWorkName == 1 && steps[5].fields.imagesMayBeShown == 1" class="p-note">Please note that your name, your registration number, your work, the registration date and the name of your work shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 1 && steps[5].fields.publishWorkName == 0 && steps[5].fields.imagesMayBeShown == 1" class="p-note">Please note that only your name, your registration number, the registration date and your work shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 1 && steps[5].fields.publishWorkName == 1 && steps[5].fields.imagesMayBeShown == 0" class="p-note">Please note that your name, your registration number, the registration date and the name of your work shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 1 && steps[5].fields.publishWorkName == 0 && steps[5].fields.imagesMayBeShown == 0" class="p-note">Please note that only your name, the registration date and your registration number shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 0 && steps[5].fields.publishWorkName == 1 && steps[5].fields.imagesMayBeShown == 1" class="p-note">Please note that your name, your registration number, your work and the name of your work shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 0 && steps[5].fields.publishWorkName == 0 && steps[5].fields.imagesMayBeShown == 1" class="p-note">Please note that only your name, your registration number and your work shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 0 && steps[5].fields.publishWorkName == 1 && steps[5].fields.imagesMayBeShown == 0" class="p-note">Please note that your name, your registration number and the name of your work shall be published in the register.</small>
                        <small v-if="steps[5].fields.publishRegistrationDate == 0 && steps[5].fields.publishWorkName == 0 && steps[5].fields.imagesMayBeShown == 0" class="p-note">Please note that only your name and your registration number shall be published in the register.</small>

                    </div>
                </div>
            </div>

            <div v-if="currentStep == 6" class="registration-step-container">
                <div class="step-header">
                    <h3>Registration overview</h3>
                    <p v-if="!readonly && type != 'client'">Below you'll find an overview of the registration as you entered it. You may proceed to payment, and the NFT will be created.</p>
                    <p v-if="readonly || type == 'client'">Below you'll find an overview of the registration as you entered it.</p>
                </div>

                <div class="step-content">
                    <div class="registration-overview-container">

                        <div class="overview-section">
                            <h3>Registration information</h3>
                            <table>
                                <tr v-if="registration.registration_code">
                                    <td>Registration number:</td>
                                    <td>{{ registration.registration_code }}</td>
                                </tr>
                                <tr v-if="registration.registration_completed_at">
                                    <td>Registration date:</td>
                                    <td>{{ format(parse(registration.registration_completed_at, 'yyyy-MM-dd H:mm:ss', new Date()), 'yyyy/MM/dd H:mm:ss') }}</td>
                                </tr>
                                <tr v-if="registration.expiry_date">
                                    <td>Extension date:</td>
                                    <td>{{ format(parse(registration.expiry_date, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd') }}</td>
                                </tr>
                            </table>
                        </div>


                        <div class="overview-section">
                            <h3>Current copyright owners or exclusive licensees</h3>
                            <div class="overview-copyright-owner" v-for="(copyrightOwner, index) in steps[1].fields.copyrightOwners">
                                <div class="overview-subsection">
                                    <h4>Current owner of rights{{index + 1}}</h4>
                                    <table>
                                        <tr v-if="copyrightOwner.company">
                                            <td>Company:</td>
                                            <td>{{ copyrightOwner.company }}</td>
                                        </tr>
                                        <tr v-if="copyrightOwner.cocNumber">
                                            <td>Chamber of Commerce Number:</td>
                                            <td>{{ copyrightOwner.cocNumber }}</td>
                                        </tr>
                                        <tr v-if="copyrightOwner.firstName || copyrightOwner.lastName">
                                            <td>Name:</td>
                                            <td>{{ copyrightOwner.firstName }} {{ copyrightOwner.lastName }}</td>
                                        </tr>
                                        <tr v-if="copyrightOwner.birthdate">
                                            <td>Birth date:</td>
                                            <td>{{ copyrightOwner.formattedBirthdate }}</td>
                                        </tr>                                        
                                        <tr v-if="copyrightOwner.email">
                                            <td>Email:</td>
                                            <td>{{ copyrightOwner.email }}</td>
                                        </tr>
                                        <tr v-if="copyrightOwner.street || copyrightOwner.zipcode || copyrightOwner.city || copyrightOwner.state || copyrightOwner.country_id">
                                            <td>Address:</td>
                                            <td>
                                                <template v-if="copyrightOwner.street">{{ copyrightOwner.street }} {{ copyrightOwner.housenumber }}<br></template>
                                                <template v-if="copyrightOwner.zipcode || copyrightOwner.city">{{ copyrightOwner.zipcode }} {{ copyrightOwner.city }}<br></template>
                                                <template v-if="copyrightOwner.state">{{ copyrightOwner.state }}<br></template>
                                                <template v-if="copyrightOwner.country_id">{{ countryName(copyrightOwner.country_id) }}<br></template>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="overview-section">
                            <h3>Creators</h3>
                            <div class="overview-creator" v-for="(creator, index) in steps[2].fields.creators">
                                <div class="overview-subsection">
                                    <h4>Creator {{index + 1}}</h4>
                                    <table>
                                        <tr v-if="creator.firstName || creator.lastName">
                                            <td>Name:</td>
                                            <td>{{ creator.firstName }} {{ creator.lastName }}</td>
                                        </tr>
                                        <tr v-if="creator.company">
                                            <td>Name:</td>
                                            <td>{{ creator.company }}</td>
                                        </tr>                                        
                                    </table>
                                </div>
                            </div>                            
                            
                            <div v-if="steps[2].fields.workCreationDate" class="overview-subsection">
                                <h4>When was the work created?</h4>
                                <p>{{ format(steps[2].fields.workCreationDate, 'yyyy/MM/dd') }}</p>
                            </div>

                            <div v-if="steps[2].fields.workTransferDate" class="overview-subsection">
                                <h4>When was the work transferred to the current copyright owner(s) of rights?</h4>
                                <p>{{ format(steps[2].fields.workTransferDate, 'yyyy/MM/dd') }}</p>
                            </div>

                            <div v-if="steps[2].fields.workCreationDate && filesByType('proof_creation_date').length > 0" class="overview-subsection">
                                <h4>Proof uploaded</h4>
                                <ul v-if="filesByType('proof_creation_date').length > 0" class="overview-files">
                                    <li v-for="file in filesByType('proof_creation_date')">
                                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())" class="image-preview">
                                            <img :src="'/image-proxy/' + file.id">
                                        </div>
                                        <div v-if="['doc', 'docx', 'pdf'].includes(file.extension.toLowerCase())" class="overview-file-preview">
                                            <img :src="'/images/file-' + file.extension + '.png'">
                                        </div>                                        
                                        <div class="overview-file-name">{{file.file_name}}</div>
                                        <div class="overview-file-download"><a :href="downloadFileUrl + '?fileId=' + file.id"><i class="pi pi-download"></i></a></div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="steps[2].fields.workTransferDate && filesByType('proof_transfer_date').length > 0" class="overview-subsection">
                                <h4>Deed of transfer uploaded</h4>
                                <ul v-if="filesByType('proof_transfer_date').length > 0" class="overview-files">
                                    <li v-for="file in filesByType('proof_transfer_date')">
                                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())" class="image-preview">
                                            <img :src="'/image-proxy/' + file.id">
                                        </div>
                                        <div v-if="['doc', 'docx', 'pdf'].includes(file.extension.toLowerCase())" class="overview-file-preview">
                                            <img :src="'/images/file-' + file.extension + '.png'">
                                        </div>                                        
                                        <div class="overview-file-name">{{file.file_name}}</div>
                                        <div class="overview-file-download"><a :href="downloadFileUrl + '?fileId=' + file.id"><i class="pi pi-download"></i></a></div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="steps[2].fields.workCreationDate" class="overview-subsection">
                                <h4>Do you want to publish the creation date?</h4>
                                <p>{{ steps[2].fields.publishCreationDate == 1 ? 'Yes' : 'No' }}</p>
                            </div>

                            <div v-if="steps[2].fields.workTransferDate" class="overview-subsection">
                                <h4>Do you want to publish the transfer date?</h4>
                                <p>{{ steps[2].fields.publishTransferDate == 1 ? 'Yes' : 'No' }}</p>
                            </div>

                            <div class="overview-subsection" v-if="creatorsThatAreNotCopyrightOwners.count > 0">
                                <h4>Did {{ creatorsThatAreNotCopyrightOwners.names }} waive {{ creatorsThatAreNotCopyrightOwners.count == 1 ? 'his/her' : 'their' }} moral rights?</h4>
                                <p>{{ steps[2].fields.moralRights == 1 ? 'Yes' : 'No' }}</p>                             
                            </div>

                            <div v-if="filesByType('proof_waive_moral_rights').length > 0" class="overview-subsection">
                                <h4>Proof uploaded</h4>
                                <ul v-if="filesByType('proof_waive_moral_rights').length > 0" class="overview-files">
                                    <li v-for="file in filesByType('proof_waive_moral_rights')">
                                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())" class="image-preview">
                                            <img :src="'/image-proxy/' + file.id">
                                        </div>
                                        <div v-if="['doc', 'docx', 'pdf'].includes(file.extension.toLowerCase())" class="file-preview">
                                            <img :src="'/images/file-' + file.extension + '.png'">
                                        </div>                                        
                                        <div class="overview-file-name">{{file.file_name}}</div>
                                        <div class="overview-file-download"><a :href="downloadFileUrl + '?fileId=' + file.id"><i class="pi pi-download"></i></a></div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div v-if="steps[3].fields.selectedCategory" class="overview-section">
                            <h3>Class</h3>
                            <p>{{ categoryCode(steps[3].fields.selectedCategory) }}. {{ categoryName(steps[3].fields.selectedCategory) }} <template v-if="steps[3].fields.selectedSubcategory">/ {{ subcategoryCode(steps[3].fields.selectedSubcategory) }}. {{ subcategoryName(steps[3].fields.selectedSubcategory) }}</template></p>
                        </div>

                        <div class="overview-section">
                            <h3>Scope of protection</h3>
                            
                            <div class="overview-subsection">
                                <h4>Does the work consist of a combination of original elements?</h4>
                                <p>{{ steps[4].fields.originalElements == 1 ? 'Yes' : 'No' }}</p>
                            </div>

                            <div class="overview-subsection">
                                <h4>Please describe the combination of original elements (this part shall not be made available to public)</h4>
                                <p>{{ steps[4].fields.originalElementsDescription }}</p>
                            </div>

                            <div class="overview-subsection">
                                <h4>How does the work differ from other existing works?</h4>
                                <p>{{ steps[4].fields.howDiffers }}</p>
                            </div>
                        </div>

                        <div class="overview-section">
                            <h3>Work</h3>
                            <div v-if="steps[5].fields.workName" class="overview-subsection">
                                <h4>Name of the work</h4>
                                <p>{{ steps[5].fields.workName }}</p>
                            </div>

                            <div v-if="steps[5].fields.workName" class="overview-subsection">
                                <h4>Do you want to publish the name of your work in the Copyrights Register?</h4>
                                <p>{{ steps[5].fields.publishWorkName == 1 ? 'Yes' : 'No' }}</p>
                            </div>

                            <div class="overview-subsection">
                                <h4>Do you want to publish the registration date of your work in the public Copyrights Register?</h4>
                                <p>{{ steps[5].fields.publishRegistrationDate == 1 ? 'Yes' : 'No' }}</p>
                            </div>

                            <div v-if="workWasUploaded" class="overview-subsection">
                                <h4>Work uploaded</h4>
                                <ul class="overview-files">

                                    <li v-for="file in filesByType('main_image')">
                                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())" class="image-preview">
                                            <img :src="'/image-proxy/' + file.id" onerror="this.src='/images/registration-placeholder.png'">
                                        </div>
                                        <div v-if="['doc', 'docx', 'pdf'].includes(file.extension.toLowerCase())" class="overview-file-preview">
                                            <img :src="'/images/file-' + file.extension + '.png'">
                                        </div>                                        
                                        <div class="overview-file-name">{{file.file_name}}</div>
                                        <div v-if="fileDescriptions[file.id]" class="overview-file-description">{{fileDescriptions[file.id]}}</div>
                                        
                                        <div class="overview-file-download"><a :href="downloadFileUrl + '?fileId=' + file.id"><i class="pi pi-download"></i></a></div>
                                    </li>

                                    <li v-for="file in filesByType('work')">
                                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())" class="image-preview">
                                            <img :src="'/image-proxy/' + file.id">
                                        </div>
                                        <div v-if="['doc', 'docx', 'pdf'].includes(file.extension.toLowerCase())" class="overview-file-preview">
                                            <img :src="'/images/file-' + file.extension + '.png'">
                                        </div>                                        
                                        <div class="overview-file-name">{{file.file_name}}</div>
                                        <div v-if="fileDescriptions[file.id]" class="overview-file-description">{{fileDescriptions[file.id]}}</div>
                                        <div class="overview-file-download"><a :href="downloadFileUrl + '?fileId=' + file.id"><i class="pi pi-download"></i></a></div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="1==2" class="overview-subsection">
                                <h4>When were the copyrights with regard to the work obtained?</h4>
                                <p>{{ steps[5].fields.copyrightDate }}</p>
                            </div>

                            <div v-if="filesByType('proof_notary_act').length > 0" class="overview-subsection">
                                <h4>Proof uploaded</h4>
                                <ul v-if="filesByType('proof_notary_act').length > 0" class="overview-files">
                                    <li v-for="file in filesByType('proof_notary_act')">
                                        <div v-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.extension.toLowerCase())" class="image-preview">
                                            <img :src="'/image-proxy/' + file.id">
                                        </div>
                                        <div v-if="['doc', 'docx', 'pdf'].includes(file.extension.toLowerCase())" class="overview-file-preview">
                                            <img :src="'/images/file-' + file.extension + '.png'">
                                        </div>                                        
                                        <div class="overview-file-name">{{file.file_name}}</div>
                                        <div class="overview-file-download"><a :href="downloadFileUrl + '?fileId=' + file.id"><i class="pi pi-download"></i></a></div>
                                    </li>
                                </ul>
                            </div>

                            <div v-if="workWasUploaded" class="overview-subsection">
                                <h4>Do you want to publish your work in the Copyrights Register?</h4>
                                <p>{{ steps[5].fields.imagesMayBeShown == 1 ? 'Yes' : 'No' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="currentStep == 7" class="registration-step-container">
                <div class="step-header">
                    <h3>Payment</h3>
                </div>

                <div class="step-content">
                    <p>After payment, your registration will be uploaded to a decentralized environment and an NFT will be created, proving ownership of the registration.</p>
                    
                    <div class="period-selector-container">
                        <div class="period-container" :class="{'selected' : steps[7].fields.selectedPeriod == 5}">
                            <label class="radio-container" :class="{'selected' : steps[7].fields.selectedPeriod == 5}" @click="steps[7].fields.selectedPeriod = 5">
                                Register for 5 years
                                <input type="radio" :checked="steps[7].fields.selectedPeriod == 5" name="selectedPeriod" value="5">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                        <div class="period-container" :class="{'selected' : steps[7].fields.selectedPeriod == 10}">
                            <label class="radio-container" :class="{'selected' : steps[7].fields.selectedPeriod == 10}" @click="steps[7].fields.selectedPeriod = 10">
                                Register for 10 years
                                <input type="radio" :checked="steps[7].fields.selectedPeriod == 10" name="selectedPeriod" value="10">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    
                    <div v-if="registration.lawfirm.free_registrations == 0 || (registration.lawfirm.free_registrations == 1 && steps[7].fields.selectedPeriod == 10)" class="pricing-table-container">
                        <table class="pricing-table">
                            <tr v-if="!vatReversed">
                                <td>Base fee</td>
                                <td>{{ numeralFormat(feeExcludingVat, '0,0.00') }}</td>
                            </tr>
                            <tr v-if="!vatReversed">
                                <td>VAT (21%)</td>
                                <td>&euro; {{ numeralFormat(vat, '0,0.00') }}</td>
                            </tr>
                            <tr>
                                <td>Total amount due</td>
                                <td v-if="vatReversed">&euro; {{ numeralFormat(feeExcludingVat, '0,0.00') }}</td>
                                <td v-if="!vatReversed">&euro; {{ numeralFormat(feeIncludingVat, '0,0.00') }}</td>
                            </tr>
                        </table>
                    </div>
                 
                    <PaymentForm :registration="registration" :payment-methods="paymentMethods" :issuers="issuers" :mode="'register-copyright'" :period="steps[7].fields.selectedPeriod" :submit="submitPaymentForm"></PaymentForm>
                </div>
            </div>

            <div v-if="currentStep == 8" class="registration-step-container">
                <div class="step-header">
                    <h3>NFT creation</h3>
                    <p>Your entry will now be registered as an NFT on the Ethereum blockchain. After creation, the NFT will be sent to a wallet. If you own a wallet yourself, please enter the wallet-address below. If not, we can store the NFT safely for you.</p>
                </div>
            </div>
        </div>

        <div v-if="registration.forced_taken_over != 1 && (!readonly && steps[4].fields.originalElements != 0)" class="steps-buttons-container">
            <div class="previous-button-container">
                <button v-if="currentStep > 1 && (registration.status != 'preregistration-done' || type == 'representative')" class="button button-default" @click="previousStep()"><i class="pi pi-chevron-left"></i>&nbsp;&nbsp;Previous step</button>
            </div>

            <div class="next-button-container">
                <button v-if="currentStep < 5" class="button button-primary" @click="nextStep()">Next step&nbsp;&nbsp;<i class="pi pi-chevron-right"></i></button>
                <button v-if="currentStep == 5" class="button button-primary" @click="nextStep()">
                    To overview&nbsp;&nbsp;<i class="pi pi-chevron-right"></i>
                </button>
                <button v-if="currentStep == 6 && type == 'representative'" class="button button-primary" @click="nextStep()">
                    To payment&nbsp;&nbsp;<i class="pi pi-chevron-right"></i>
                </button>
                <button v-if="currentStep == 6 && type == 'client' && registration.status != 'preregistration-done' && registration.lawfirm.account_active == 1" class="button button-primary" @click="finishPreregistration($event)">
                    Send to IP Representative&nbsp;&nbsp;<i class="pi pi-chevron-right"></i>
                </button>
                <button v-if="currentStep == 7" class="button button-primary payment-button" @click="doSubmitPaymentForm()">
                    <img src="images/icon-lock@2x.png" alt="Pay safely"> Make payment
                    &nbsp;&nbsp;<i class="pi pi-chevron-right"></i>
                </button>

                <p style="max-width: 400px; margin: 0 0 0 40px; text-align: right;" v-if="currentStep == 6 && type == 'client' && registration.status != 'preregistration-done' && registration.lawfirm.account_active == 0">
                    The account of this IP Representative is not yet activated, and therefore you cannot complete this registration yet.
                </p>

            </div>
        </div>
        `,
    props: [
        'categories',
        'lawfirm',
        'registration',
        'paymentMethods',
        'issuers',
        'readonly',
        'type',
        'saveRegistrationUrl',
        'uploadFilesUrl',
        'removeFileUrl',
        'downloadFileUrl',
    ],
    setup() {
        return { v$: useVuelidate() }
    },
    inject: ['parse', 'format'],
    data: function () {
        return {
            showUsRegistrationModal: false,
            registrationCode: null,
            currentStep: 1,
            registrationFinished: false,
            fileErrors: {
                main_image: false,
                work: false,
            },
            fileDescriptions: {},
            proceedWithUpload: true,
            submitPaymentForm: false,
            workNameRequiredClassIds: [2, 15],
            steps: {
                1: {
                    id: 1,
                    code: 'copyright-owners',
                    label: 'Copyright owner(s)',
                    fields: {
                        copyrightOwners: [],
                    },
                },
                2: {
                    id: 2,
                    code: 'creators',
                    label: 'Creator(s)',
                    fields: {
                        creators: [],
                        workCreationDate: this.registration ? this.registration.work_creation_date : null,
                        workTransferDate: this.registration ? this.registration.work_transfer_date : null,
                        publishCreationDate: this.registration ? this.registration.publish_creation_date : null,
                        publishTransferDate: this.registration ? this.registration.publish_transfer_date : null,
                        moralRights: this.registration ? this.registration.moral_rights : null,
                    },
                },
                3: {
                    id: 3,
                    code: 'category',
                    label: 'Class',
                    fields: {
                        selectedCategory: this.registration ? this.registration.registration_category_id : null,
                        selectedSubcategory: this.registration ? this.registration.registration_subcategory_id : null,
                    },
                },
                4: {
                    id: 4,
                    code: 'protection',
                    label: 'Scope of protection',
                    fields: {
                        originalElements: this.registration ? this.registration.original_elements : null,
                        originalElementsDescription: this.registration ? this.registration.original_elements_description : null,
                        howDiffers: this.registration ? this.registration.how_differs : null,
                    },
                },
                5: {
                    id: 5,
                    code: 'work',
                    label: 'Your work',
                    fields: {
                        publishWorkName: this.registration ? this.registration.publish_work_name : 1,
                        publishRegistrationDate: this.registration ? this.registration.publish_registration_date : 1,
                        workName: this.registration ? this.registration.work_name : null,
                        imagesMayBeShown: this.registration ? this.registration.images_may_be_shown : 1,
                    },
                },
                6: {
                    id: 6,
                    code: 'overview',
                    label: 'Overview',
                },
            },

            countries: [],
        }
    },
    validations() {
        let validationRules
        if (this.type == 'representative') {
            validationRules = {
                steps: {
                    1: {
                        fields: {
                            copyrightOwners: [],
                        },
                    },
                    2: {
                        fields: {
                            creators: [],
                        },
                    },
                    3: {
                        fields: {
                            selectedCategory: { required },
                            selectedSubcategory: { required },
                        },
                    },
                    4: {
                        fields: {
                            originalElements: { required },
                            originalElementsDescription: { required },
                            howDiffers: { required },
                        },
                    },
                    5: {
                        fields: {
                            publishWorkName: { required },
                            publishRegistrationDate: { required },
                            workName: {},
                        },
                    },
                },
            }
        } else {
            validationRules = {
                steps: {
                    1: {
                        fields: {
                            copyrightOwners: [],
                        },
                    },
                    2: {
                        fields: {
                            creators: [],
                        },
                    },
                    3: {
                        fields: {
                            selectedCategory: {},
                            selectedSubcategory: {},
                        },
                    },
                    4: {
                        fields: {
                            originalElements: {},
                            originalElementsDescription: {},
                            howDiffers: {},
                        },
                    },
                    5: {
                        fields: {
                            publishWorkName: {},
                            publishRegistrationDate: {},
                            workName: {},
                        },
                    },
                },
            }
        }
        this.steps[1].fields.copyrightOwners.forEach((copyrightOwner, index) => {
            const copyrightOwnerValidationRules = {
                firstName: { required },
                lastName: { required },
                birthdate: {
                    day: { required },
                    month: { required },
                    year: { required },
                },
            }

            if (copyrightOwner.company) {
                copyrightOwnerValidationRules.cocNumber = { required }
            }

            validationRules.steps[1].fields.copyrightOwners.push(copyrightOwnerValidationRules)
        })

        this.steps[2].fields.creators.forEach((creator, index) => {
            if (creator.type == 'company') {
                validationRules.steps[2].fields.creators.push({
                    company: { required },
                })
            } else {
                validationRules.steps[2].fields.creators.push({
                    firstName: { required },
                    lastName: { required },
                })
            }
        })

        if (this.creatorsThatAreNotCopyrightOwners.count > 0) {
            if (this.type == 'representative') {
                validationRules.steps[2].fields.moralRights = { required }
                validationRules.steps[2].fields.workTransferDate = { required }
                validationRules.steps[2].fields.publishTransferDate = {
                    required,
                }
            } else {
                validationRules.steps[2].fields.moralRights = {}
                validationRules.steps[2].fields.workTransferDate = {}
                validationRules.steps[2].fields.publishTransferDate = {}
            }
        } else {
            validationRules.steps[2].fields.workCreationDate = { required }
            if (this.type == 'representative') {
                validationRules.steps[2].fields.publishCreationDate = {
                    required,
                }
            } else {
                validationRules.steps[2].fields.publishCreationDate = {}
            }
        }

        if (this.workNameRequiredClassIds.includes(this.steps[3].fields.selectedCategory) && this.type == 'representative') {
            validationRules.steps[5].fields.workName = { required }
        }

        return validationRules
    },

    beforeMount() {
        if (this.readonly) {
            this.currentStep = 6
        } else {
            this.currentStep = parseInt(this.registration.current_step)
            const url = location.protocol + '//' + location.host + location.pathname + '?step=' + this.currentStep
            window.history.replaceState({ path: url }, document.title, url)
        }

        if (this.registration.work_creation_date) {
            this.steps[2].fields.workCreationDate = this.parse(this.registration.work_creation_date, 'yyyy/MM/dd', new Date())
        }

        if (this.registration.work_transfer_date) {
            this.steps[2].fields.workTransferDate = this.parse(this.registration.work_transfer_date, 'yyyy/MM/dd', new Date())
        }

        if (this.type == 'representative') {
            this.steps[7] = {
                id: 7,
                code: 'payment',
                label: 'Payment',
                fields: {
                    selectedPeriod: 5,
                },
            }

            this.steps[8] = {
                id: 8,
                code: 'nft',
                label: 'NFT creation',
            }
        }
    },

    mounted() {
        fetch('/api/countries')
            .then((response) => response.json())
            .then((countries) => {
                this.countries = countries
            })

        window.addEventListener('popstate', (e) => {
            if (!e.state || !e.state.path) {
                return
            }

            const queryString = window.location.search
            const urlParams = new URLSearchParams(queryString)
            const newStep = urlParams.get('step')

            if (this.currentStep < newStep) {
                this.nextStep()
            } else {
                this.previousStep()
            }
        })

        this.categories.sort((a, b) => {
            return parseInt(a.code, 10) < parseInt(b.code, 10) ? -1 : 1
        })

        if (this.registration) {
            if (this.registration.copyright_owners.length > 0) {
                this.registration.copyright_owners.forEach((copyrightOwner) => {
                    const birthdate = this.parse(copyrightOwner.date_of_birth, 'yyyy/MM/dd', new Date())

                    this.steps[1].fields.copyrightOwners.push({
                        company: copyrightOwner.company,
                        cocNumber: copyrightOwner.coc_number,
                        firstName: copyrightOwner.first_name,
                        lastName: copyrightOwner.last_name,
                        birthdate: {
                            day: parseInt(this.format(birthdate, 'd'), 10),
                            month: parseInt(this.format(birthdate, 'M'), 10),
                            year: parseInt(this.format(birthdate, 'yyyy'), 10),
                        },
                        formattedBirthdate: this.format(birthdate, 'yyyy/MM/dd'),
                        street: copyrightOwner.street,
                        housenumber: copyrightOwner.housenumber,
                        zipcode: copyrightOwner.zipcode,
                        city: copyrightOwner.city,
                        state: copyrightOwner.state,
                        country_id: copyrightOwner.country_id,
                    })
                })
            } else {
                this.steps[1].fields.copyrightOwners.push({
                    company: null,
                    cocNumber: null,
                    firstName: null,
                    lastName: null,
                    birthdate: {
                        day: null,
                        month: null,
                        year: null,
                    },
                    street: null,
                    housenumber: null,
                    zipcode: null,
                    city: null,
                    state: null,
                    country_id: null,
                })
            }

            if (this.registration.creators.length > 0) {
                this.registration.creators.forEach((creator) => {
                    this.steps[2].fields.creators.push({
                        type: creator.company ? 'company' : 'person',
                        company: creator.company,
                        firstName: creator.first_name,
                        lastName: creator.last_name,
                    })
                })
            }

            if (this.currentStep == 2 && this.steps[2].fields.creators.length == 0) {
                this.addCreator()
            }

            if (this.registration.status == 'waiting-for-payment') {
                this.registrationFinished = true
            }

            if (this.registration.files) {
                this.registration.files.forEach((file) => {
                    this.fileDescriptions[file.id] = file.description
                })
            }
        }
    },
    methods: {
        saveStep(next) {
            let postParams
            postParams = {
                step: this.currentStep,
            }

            if (this.currentStep == 1) {
                this.steps[this.currentStep].fields.copyrightOwners.forEach((copyrightOwner) => {
                    const birthdate = copyrightOwner.birthdate.year + '-' + copyrightOwner.birthdate.month + '-' + copyrightOwner.birthdate.day
                    copyrightOwner.dateOfBirth = birthdate
                })
            }

            if (this.steps.hasOwnProperty(this.currentStep) && this.steps[this.currentStep].hasOwnProperty('fields')) {
                postParams.fields = this.steps[this.currentStep].fields
            }

            if (this.registration) {
                postParams.registrationCode = this.registration.registration_code
            }

            if (next) {
                postParams.newStep = this.currentStep < 8 ? this.currentStep + 1 : 8
            } else {
                postParams.newStep = this.currentStep > 1 ? this.currentStep - 1 : 1
            }

            if (this.currentStep == 2) {
                if (this.creatorsThatAreNotCopyrightOwners.count == 0) {
                    postParams.fields.workTransferDate = null
                } else {
                    postParams.fields.workCreationDate = null
                }
            }

            postParams.fileDescriptions = this.fileDescriptions

            this.axios
                .post(this.saveRegistrationUrl, {
                    postParams,
                })
                .then((response) => {
                    this.registration.status = response.data.registrationStatus
                    this.registration.forced_taken_over = response.data.forcedTakenOver
                    this.$forceUpdate()
                })
        },
        previousStep() {
            this.saveStep(false)
            this.currentStep = this.currentStep > 1 ? this.currentStep - 1 : 1
            const url = location.protocol + '//' + location.host + location.pathname + '?step=' + this.currentStep
            window.history.pushState({ path: url }, document.title, url)
            window.scrollTo({ top: 0, behavior: 'smooth' })
        },
        async nextStep() {
            let isFormCorrect

            if (this.v$.steps.hasOwnProperty(this.currentStep) && this.v$.steps[this.currentStep].hasOwnProperty('fields')) {
                isFormCorrect = await this.v$.steps[this.currentStep].fields.$validate()
                if (this.currentStep == 5) {
                    if (!this.workNameRequiredClassIds.includes(this.steps[3].fields.selectedCategory)) {
                        if (this.filesByType('main_image').length == 0 && this.type == 'representative') {
                            this.fileErrors.main_image = true
                            isFormCorrect = false
                        }
                    }
                } else if (this.currentStep == 1 && this.steps[2].fields.creators.length == 0) {
                    this.addCreator()
                }
            } else {
                isFormCorrect = true
            }

            if (isFormCorrect) {
                this.saveStep(true)
                this.currentStep = this.currentStep + 1
                const url = location.protocol + '//' + location.host + location.pathname + '?step=' + this.currentStep
                window.history.pushState({ path: url }, document.title, url)
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        },
        finishPreregistration(event) {
            event.preventDefault()
            let postParams

            postParams = {
                step: 'finish-preregistration',
                registrationCode: this.registration.registration_code,
            }

            this.axios
                .post(this.saveRegistrationUrl, {
                    postParams,
                })
                .then((response) => {
                    this.registration.status = 'preregistration-done'
                    this.$forceUpdate()
                })
        },
        copyrightOwnerClicked(event) {
            const value = event.value
            if (value == 1) {
                this.addCopyrightOwner()
            } else {
                this.steps[1].fields.copyrightOwners = []
            }
        },
        addCopyrightOwner() {
            this.steps[1].fields.copyrightOwners.push({
                firstName: null,
                lastName: null,
                street: null,
                housenumber: null,
                zipcode: null,
                city: null,
                state: null,
                email: null,
                telephone: null,
                company: null,
                birthdate: {
                    day: null,
                    month: null,
                    year: null,
                },
                country_id: null,
            })
        },
        removeCopyrightOwner(event, copyrightOwnerId) {
            event.preventDefault()
            if (this.steps[1].fields.copyrightOwners.length > 1) {
                this.steps[1].fields.copyrightOwners.splice(copyrightOwnerId, 1)
            }
        },
        creatorClicked(event) {
            const value = event.value
            this.steps[2].fields.creators = []
            if (value == 0) {
                this.addCreator()
            }
        },
        otherCreatorClicked(event) {
            const value = event.value
            this.steps[2].fields.creators = []
            if (value == 1) {
                this.addCreator()
            }
        },
        addCreator() {
            this.steps[2].fields.creators.push({
                type: 'person',
                firstName: null,
                lastName: null,
            })
        },
        removeCreator(event, creatorId) {
            event.preventDefault()
            this.steps[2].fields.creators.splice(creatorId, 1)
            if (this.steps[2].fields.creators.length == 0) {
                this.steps[2].fields.soleCreator = 1
            }
        },
        beforeUpload(request) {
            request.formData.append('registrationCode', this.registration.registration_code)
            return request
        },
        afterUpload(request) {
            const response = JSON.parse(request.xhr.response)
            this.registration.files = response.registration.files
            this.updateFileErrors()
            this.$forceUpdate()
        },
        removeFile(event, fileId) {
            event.preventDefault()

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to remove this file?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.axios
                        .post(this.removeFileUrl, {
                            fileId: fileId,
                            registrationCode: this.registration.registration_code,
                        })
                        .then((response) => {
                            this.registration.files = response.data.registration.files
                            this.$forceUpdate()
                        })
                },
            })
        },
        downloadFile(event, fileId) {
            event.preventDefault()
            window.location.href = this.downloadFileUrl + '?fileId=' + fileId
        },
        updateFileErrors() {
            if (this.currentStep == 2) {
                if (this.filesByType('proof_creation_date').length > 0 && this.fileErrors.proof_creation_date) {
                    this.fileErrors.proof_creation_date = false
                }

                if (this.filesByType('proof_transfer_date').length > 0 && this.fileErrors.proof_transfer_date) {
                    this.fileErrors.proof_transfer_date = false
                }
                if (this.filesByType('proof_waive_moral_rights').length > 0 && this.fileErrors.proof_waive_moral_rights) {
                    this.fileErrors.proof_waive_moral_rights = false
                }

                if (this.filesByType('proof_agree_specific_use').length > 0 && this.fileErrors.proof_agree_specific_use) {
                    this.fileErrors.proof_agree_specific_use = false
                }
            }

            if (this.currentStep == 5) {
                if (this.filesByType('main_image').length > 0 && this.fileErrors.main_image) {
                    this.fileErrors.main_image = false
                }
                if (this.filesByType('work').length > 0 && this.fileErrors.work) {
                    this.fileErrors.work = false
                }
                if (this.filesByType('proof_notary_act').length > 0 && this.fileErrors.proof_notary_act) {
                    this.fileErrors.proof_notary_act = false
                }
            }
        },
        isEurope(countryId) {
            let isEurope = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    isEurope = country.is_europe == 1
                    return false
                }
                return true
            })
            return isEurope
        },
        showStateSelector(countryId) {
            let showStateSelector = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    showStateSelector = country.show_state_selector == 1
                    return false
                }
                return true
            })
            return showStateSelector
        },
        countryName(countryId) {
            let countryName = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    countryName = country.country_name
                    return false
                }
                return true
            })
            return countryName
        },
        categoryName(id) {
            let categoryName = false
            this.categories.every((category) => {
                if (category.id === id) {
                    categoryName = category.name
                    return false
                }
                return true
            })
            return categoryName
        },
        subcategoryName(id) {
            let subcategoryName = false
            if (this.subcategories && this.subcategories.length > 0) {
                this.subcategories.every((subcategory) => {
                    if (subcategory.id === id) {
                        subcategoryName = subcategory.name
                        return false
                    }
                    return true
                })
            }
            return subcategoryName
        },
        categoryCode(id) {
            let categoryCode = false
            this.categories.every((category) => {
                if (category.id === id) {
                    categoryCode = category.code
                    return false
                }
                return true
            })
            return categoryCode
        },
        subcategoryCode(id) {
            let subcategoryCode = false
            if (this.subcategories && this.subcategories.length > 0) {
                this.subcategories.every((subcategory) => {
                    if (subcategory.id === id) {
                        subcategoryCode = subcategory.code
                        return false
                    }
                    return true
                })
            }
            return subcategoryCode
        },
        isToday(date) {
            if (date) {
                date = new Date(date)
                const today = new Date()
                return date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()
            }
            return false
        },
        filesByType(type) {
            const files = []
            this.registration.files.forEach((file) => {
                if (file.file_type == type) {
                    files.push(file)
                }
            })
            return files
        },
        doSubmitPaymentForm() {
            this.submitPaymentForm = true
        },
        changeCreatorType(index, type) {
            if (type == 'company') {
                this.steps[2].fields.creators[index].firstName = ''
                this.steps[2].fields.creators[index].lastName = ''
            } else {
                this.steps[2].fields.creators[index].company = ''
            }
        },
    },
    computed: {
        days() {
            let days = []
            for (let x = 1; x < 32; x++) {
                days.push({
                    code: x,
                    label: x,
                })
            }
            return days
        },
        months() {
            let months = [
                {
                    code: 1,
                    label: 'January',
                },
                {
                    code: 2,
                    label: 'February',
                },
                {
                    code: 3,
                    label: 'March',
                },
                {
                    code: 4,
                    label: 'April',
                },
                {
                    code: 5,
                    label: 'May',
                },
                {
                    code: 6,
                    label: 'June',
                },
                {
                    code: 7,
                    label: 'July',
                },
                {
                    code: 8,
                    label: 'August',
                },
                {
                    code: 9,
                    label: 'September',
                },
                {
                    code: 10,
                    label: 'October',
                },
                {
                    code: 11,
                    label: 'November',
                },
                {
                    code: 12,
                    label: 'December',
                },
            ]
            return months
        },
        years() {
            let years = []
            const currentYear = new Date().getFullYear()
            for (let x = currentYear; x > currentYear - 100; x--) {
                years.push({
                    code: x,
                    label: x,
                })
            }
            return years
        },
        feeExcludingVat() {
            if (this.steps[7].fields.selectedPeriod == 5) {
                return 35
            } else {
                return 65
            }
        },
        vat() {
            return this.feeExcludingVat * 0.21
        },
        feeIncludingVat() {
            return this.feeExcludingVat + this.vat
        },

        subcategories() {
            let items
            if (this.steps[3].fields.selectedCategory) {
                this.categories.forEach((category) => {
                    if (category.id == this.steps[3].fields.selectedCategory) {
                        items = category.registration_subcategories
                        items.sort((a, b) => {
                            return a.code < b.code ? -1 : 1
                        })
                        if (items.length == 1) {
                            this.steps[3].fields.selectedSubcategory = items[0].id
                        }
                    }
                })
            } else {
                items = {}
            }
            return items
        },
        copyrightOwnerNames() {
            let copyrightOwners = []
            this.steps[1].fields.copyrightOwners.forEach((copyrightOwner) => {
                copyrightOwners.push(copyrightOwner.firstName + ' ' + copyrightOwner.lastName)
            })

            if (copyrightOwners.length == 1) {
                return copyrightOwners[0]
            } else if (copyrightOwners.length == 2) {
                return copyrightOwners[0] + ' and ' + copyrightOwners[1]
            } else if (copyrightOwners.length > 2) {
                let lastCopyrightOwner = copyrightOwners.pop()
                return copyrightOwners.join(', ') + ' and ' + lastCopyrightOwner
            }
        },
        creatorsThatAreNotCopyrightOwners() {
            let creators = []
            this.steps[2].fields.creators.forEach((creator) => {
                if (creator.company || (creator.firstName && creator.lastName)) {
                    const creatorName = creator.company ? creator.company : creator.firstName + ' ' + creator.lastName
                    let creatorIsCopyrightOwner = false
                    this.steps[1].fields.copyrightOwners.forEach((copyrightOwner) => {
                        const copyrightOwnerName = copyrightOwner.firstName + ' ' + copyrightOwner.lastName
                        if (creator.company && creator.company == copyrightOwner.company) {
                            creatorIsCopyrightOwner = true
                        } else if (!creator.company && !copyrightOwner.company && creatorName == copyrightOwnerName) {
                            creatorIsCopyrightOwner = true
                        }
                    })
                    if (creatorIsCopyrightOwner == false) {
                        creators.push(creatorName)
                    }
                }
            })

            let names = ''
            if (creators.length == 1) {
                names = creators[0]
            } else if (creators.length == 2) {
                names = creators[0] + ' and ' + creators[1]
            } else if (creators.length > 2) {
                let lastCreator = creators.pop()
                names = creators.join(', ') + ' and ' + lastCreator
            }

            return {
                count: creators.length,
                names: names,
            }
        },
        vatReversed() {
            return this.registration.lawfirm.reverse_vat == 1
        },
        workWasUploaded() {
            return this.filesByType('main_image').length > 0 || this.filesByType('work').length > 0
        },
    },
}

export { CopyrightRegistrationForm }
