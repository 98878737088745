const LoginForm = {
    template: /*html*/ `
        
        <div v-if="forgotPasswordFormVisible">
            <div class="section-header">
                <p>
                    Forgot your password? No problem. Please fill in your email address and we will send you a new password which you can use immediately to log in. 
                </p>
            </div>

            <form v-if="forgotPasswordFormVisible" ref="forgotPasswordForm" class="forgot-password-form" method="POST" action="/send-password-reset-link" @submit="sendNewPassword($event)">

                <div class="inputgroup">
                    <InputText ref="forgotPasswordEmail" id="email" placeholder="Email address" name="email" v-model="forgotPasswordEmail "/>
                    <p v-if="showForgotPasswordError" class="form-error">Unfortunately we weren't able to find this email address in our database.</p>
                </div>

                <div class="inputgroup">
                    <button :disabled="resettingPassword" type="submit" class="button button-primary low-button w-auto">
                        Send me a password reset link
                    </button>
                </div>
                <div class="back-to-login">
                    <a href="#" @click="showLogin($event)">&lt; Back to login</a>
                </div>
            </form>
        </div>

        <div v-if="!forgotPasswordFormVisible">

            <div class="section-header">
                <p>Log in to go to the MY WEIP Dashboard. If you don't have an account yet, you can <a href="/register">register here</a>.</p>
            </div>

            <form class="login-form" method="POST" action="/login" autocomplete="off">
                
                <p v-if="showPasswordSent" class="info-box success-box">
                    A password reset link was sent to your email address. Please use the link to recreate your password, and you'll be able to login again.
                </p>

                <p v-if="errorMessage && showErrorMessage" class="info-box error-box">
                    {{ errorMessage }}
                </p>

                <div class="form-group">
                    <InputText ref="emailInput" placeholder="Email address" name="email" v-model="email" />
                </div>

                <div class="form-group">
                    <Password ref="passwordInput" placeholder="Password" name="password" :toggleMask="true" :feedback="false" v-model="password" />
                </div>

                <div class="form-group-splitter">
                    <button type="submit" class="button button-primary low-button w-auto">
                        Log in
                    </button>
                    
                    <div class="password-forgotten-link-container">
                        <a href="#" @click="showPasswordForgotten($event)">
                            Forgot password
                        </a>
                    </div>
                </div>
                <input type="hidden" name="checkout-process" :value="fromCheckoutProcess ? 1 : 0">
            </form>
        </div>
        `,
    props: {
        errorMessage: '',
        fromCheckoutProcess: false,
    },
    data: function () {
        return {
            forgotPasswordFormVisible: false,
            showPasswordSent: false,
            showForgotPasswordError: false,
            showErrorMessage: true,
            resettingPassword: false,
            email: '',
            password: '',
            forgotPasswordEmail: '',
        }
    },
    methods: {
        showLogin(event) {
            event.preventDefault()
            this.showLoginForm()
        },
        showPasswordForgotten(event) {
            event.preventDefault()
            this.showPasswordForgottenForm()
        },
        showPasswordForgottenForm() {
            this.forgotPasswordFormVisible = true
        },
        showLoginForm() {
            this.forgotPasswordFormVisible = false
        },
        sendNewPassword(event) {
            event.preventDefault()
            this.showForgotPasswordError = false
            this.resettingPassword = true

            const emailInput = this.forgotPasswordEmail
            const formData = `email=${encodeURIComponent(emailInput)}`
            const req = new XMLHttpRequest()
            req.open('POST', this.$refs.forgotPasswordForm.action, true)
            req.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')

            req.onload = () => {
                if (req.status >= 200 && req.status < 400) {
                    setTimeout(() => {
                        this.showLoginForm()
                        setTimeout(() => {
                            this.email = emailInput
                            this.password = ''
                            this.showPasswordSent = true
                            this.resettingPassword = false
                            this.showErrorMessage = false
                        }, 0)
                    }, 0)
                } else if (req.status === 404) {
                    this.showForgotPasswordError = true
                    this.resettingPassword = false
                }
            }

            req.send(formData)
        },
    },
}

export { LoginForm }
