import useVuelidate from '@vuelidate/core'
import { required, email, helpers, sameAs, sameAsRawValue } from '@vuelidate/validators'

const UserForm = {
    template: /*html*/ `
        <ConfirmPopup></ConfirmPopup>
        <div class="registration-steps-container">
            <div class="registration-step-container">
                <div class="step-header">
                    <h3 v-if="ownAccount">My account</h3>
                    <h3 v-if="!ownAccount">User</h3>
                </div>

                <div class="step-content edit-form">
                    <div class="form-group">
                        <h5 class="mb-3">User information</h5>
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <div class="inputgroup">
                                    <label for="firstName">First name *</label>    
                                    <InputText id="firstName" :class="{'p-invalid': v$.fields.firstName.$error}" v-model="fields.firstName"/>
                                </div>
                                <small v-for="error of v$.fields.firstName.$errors" class="p-error">{{error.$message}}</small>
                            </div>

                            <div class="col-6">
                                <div class="inputgroup">
                                    <label for="lastName">Last name *</label>
                                    <InputText id="lastName" :class="{'p-invalid': v$.fields.lastName.$error}" v-model="fields.lastName"/>
                                </div>
                                <small v-for="error of v$.fields.lastName.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>                        
                 
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label>Email address *</label>
                                    <InputText :class="{'p-invalid': v$.fields.email.$error}" v-model="fields.email" />
                                </div>
                                <small v-for="error of v$.fields.email.$errors" class="p-error">{{error.$message}}</small>

                                <div v-if="user && user.requested_email" class="mt-2">
                                    A verification email has been sent to you to verify your new email address. Please click on the link in the email to verify the new address. 
                                    If you didn't receive the email, we are more than happy to <a :href="'/dashboard/users/send-new-verification-email/' + user.email_verification_code">send it again.</a> You can also <a :href="'/dashboard/users/cancel-email-change/' + user.email_verification_code">cancel the change request</a>.
                                </div>
                            </div>


                            <div v-if="!ownAccount || (user && user.role == 'admin')" class="col-12">
                                <div class="inputgroup">
                                    <label for="role">Role *</label>    
                                    <Dropdown id="role" :class="{'p-invalid': v$.fields.role.$error}" v-model="fields.role" optionValue="code" optionLabel="name" :options="roles"/>
                                </div>
                                <small v-for="error of v$.fields.role.$errors" class="p-error">{{error.$message}}</small>
                            </div>                            

                            <div v-if="user" class="col-12">
                                <div class="inputgroup">
                                    <label v-if="user">Password</label>
                                    <label v-if="!user">Password *</label>
                                    <Password :class="{'p-invalid': !user && v$.fields.password.$error}" v-model="fields.password" :toggleMask="true" />
                                </div>
                                <small v-if="!user" v-for="error of v$.fields.password.$errors" class="p-error">{{error.$message}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <a v-if="ownAccount" href="#" class="delete-account-link" @click="deleteAccount($event)">Delete my account</a>
            <a v-if="user && user.role == 'admin'" href="dashboard/edit-lawfirm" class="edit-lawfirm-link">Edit IP register information</a>

        </div>

        <div class="registration-buttons-container">
            <div class="next-button-container">
                <button class="button button-primary" @click="submitForm">Save information&nbsp;&nbsp;<i class="pi pi-chevron-right"></i></button>
            </div>
        </div>

        <form ref="userForm" action="" method="post">
            <input type="hidden" name="first_name" :value="fields.firstName">
            <input type="hidden" name="last_name" :value="fields.lastName">
            <input type="hidden" name="email" :value="fields.email">
            <input type="hidden" name="password" :value="fields.password">
            <input type="hidden" name="role" :value="fields.role">
        </form>

        <form v-if="ownAccount" ref="deleteAccountForm" action="dashboard/delete-account" method="post">
            <input type="hidden" name="delete-account" value="1">
        </form>

        `,
    props: ['user', 'ownAccount'],
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            fields: {
                email: this.user ? this.user.email : null,
                firstName: this.user ? this.user.first_name : null,
                lastName: this.user ? this.user.last_name : null,
                role: this.user ? this.user.role : null,
                password: '',
            },
            roles: [
                {
                    name: 'Administrator',
                    code: 'admin',
                },
                {
                    name: 'User',
                    code: 'user',
                },
            ],
        }
    },
    validations() {
        let validationRules = {
            fields: {
                firstName: { required },
                lastName: { required },
                email: {
                    required,
                    email,
                    isUnique: helpers.withMessage('This email address is already registered in our database', helpers.withAsync(this.isEmailTaken)),
                },
                role: { required },
            },
        }

        return validationRules
    },
    methods: {
        async submitForm() {
            const valid = await this.v$.$validate()

            if (valid) {
                this.$refs.userForm.submit()
            }
        },

        deleteAccount(event) {
            event.preventDefault()

            this.$confirm.require({
                target: event.currentTarget,
                message:
                    'Are you sure you want to remove your account? All your information will be deleted, but your registrations will remain. You will be logged out of the platform.',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    setTimeout(() => {
                        this.$refs.deleteAccountForm.submit()
                    }, 0)
                },
            })
        },
        async isEmailTaken(value) {
            if (!value) {
                return true
            }
            let isTaken = false

            let url
            if (this.user) {
                let exclude = this.user.email
                url = `/api/check-email/${value}?exclude=${exclude}`
            } else {
                url = `/api/check-email/${value}`
            }

            await fetch(url)
                .then((result) => {
                    return result.json()
                })
                .then((json) => {
                    if (json.result == 'unavailable') {
                        isTaken = true
                    }
                })
            return !isTaken
        },
    },
}

export { UserForm }
