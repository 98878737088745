import useVuelidate from '@vuelidate/core'
import { required, email, helpers, sameAs, sameAsRawValue } from '@vuelidate/validators'

const LawfirmForm = {
    template: /*html*/ `

        <div class="registration-steps-container">
            <div class="registration-step-container">
                <div class="step-header">
                    <h3>Change the information or your law firm</h3>
                </div>

                <div class="step-content edit-form">
                    
                    <div class="form-group mb-3">
                        <h5 class="mb-3">Information about your lawfirm</h5>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label for="firmName">Firm name *</label>    
                                    <InputText id="firmName" :class="{'p-invalid': v$.fields.firmName.$error}" v-model="fields.firmName" />
                                    <small v-for="error of v$.fields.firmName.$errors" class="p-error">{{error.$message}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label for="cocNumber">Chamber of Commerce number *</label>
                                    <InputText id="cocNumber" :class="{'p-invalid': v$.fields.cocNumber.$error}" v-model="fields.cocNumber" />
                                    <small v-for="error of v$.fields.cocNumber.$errors" class="p-error">{{error.$message}}</small>
                                </div>
                            </div>
                        </div>
                        <div class="grid p-fluid">                                                        
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label>Registration form URL for clients</label>
                                    <p class="form-information"><a :href="lawfirm.clientUrl" target="_blank">{{lawfirm.clientUrl}}</a></p>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div class="form-group mb-3">
                        <h5 class="mb-3">Address information</h5>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-9">
                                <div class="inputgroup">
                                    <label for="streetName">Street name *</label>    
                                    <InputText id="streetName" :class="{'p-invalid': v$.fields.street.$error}" v-model="fields.street"/>
                                    <small v-for="error of v$.fields.street.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                            </div>

                            <div class="col-12 md:col-3">
                                <div class="inputgroup">
                                    <label for="housenumber">Housenumber *</label>    
                                    <InputText id="housenumber" :class="{'p-invalid': v$.fields.housenumber.$error}" v-model="fields.housenumber" />
                                    <small v-for="error of v$.fields.housenumber.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-12 md:col-4">
                                <div class="inputgroup">
                                    <label for="zipcode">Zipcode *</label>    
                                    <InputText id="zipcode" :class="{'p-invalid': v$.fields.zipcode.$error}" v-model="fields.zipcode" />
                                    <small v-for="error of v$.fields.zipcode.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                            </div>

                            <div class="col-12 md:col-8">
                                <div class="inputgroup">
                                    <label for="city">City *</label>    
                                    <InputText id="city" :class="{'p-invalid': v$.fields.city.$error}" v-model="fields.city" />
                                    <small v-for="error of v$.fields.city.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                            </div>
                        </div>

                        <div class="grid p-fluid">
                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="country">Choose country *</label>    
                                    <Dropdown id="country_id" :class="{'p-invalid': v$.fields.countryId.$error}" v-model="fields.countryId" optionValue="id" optionLabel="country_name" :options="countries" />
                                    <small v-for="error of v$.fields.countryId.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                            </div>

                            <div class="col-12 md:col-6">
                                <div class="inputgroup">
                                    <label for="vatNumber">VAT number</label>
                                    <InputText id="vatNumber" :class="{'p-invalid': v$.fields.vatNumber.$error}" v-model="fields.vatNumber" />
                                    <small v-for="error of v$.fields.vatNumber.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                            </div>

                        </div>
                    </div>



                    <div class="form-group">
                        <h5 class="mb-3">Email information</h5>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <div class="inputgroup">
                                    <label for="emailAddress">Email address *</label>    
                                    <InputText id="emailAddress" :class="{'p-invalid': v$.fields.email.$error}" v-model="fields.email" />
                                    <small v-for="error of v$.fields.email.$errors" class="p-error">{{error.$message}}</small>                                    
                                </div>

                                <div v-if="lawfirm.new_email_requested == 1" class="mt-2">
                                    A verification email has been sent to you to verify your new email address. Please click on the link in the email to verify the new address. 
                                    If you didn't receive the email, we are more than happy to <a href="/dashboard/edit-lawfirm/send-new-verification-email">send it again.</a> You can also <a href="/dashboard/edit-lawfirm/cancel-email-change">cancel the change request</a>.
                                </div>

                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="registration-buttons-container">
            <div class="next-button-container">
                <button class="button button-primary" @click="submitForm">Save information&nbsp;&nbsp;<i class="pi pi-chevron-right"></i></button>
            </div>
        </div>

        <form ref="lawfirmForm" action="dashboard/edit-lawfirm" method="post">
            <input type="hidden" name="email" :value="fields.email">
            <input type="hidden" name="cocNumber" :value="fields.cocNumber">
            <input type="hidden" name="street" :value="fields.street">
            <input type="hidden" name="housenumber" :value="fields.housenumber">
            <input type="hidden" name="zipcode" :value="fields.zipcode">
            <input type="hidden" name="city" :value="fields.city">
            <input type="hidden" name="state" :value="fields.state">
            <input type="hidden" name="telephone" :value="fields.telephone">
            <input type="hidden" name="countryId" :value="fields.countryId">
            <input type="hidden" name="vatNumber" :value="fields.vatNumber">
            <input type="hidden" name="firmName" :value="fields.firmName">
        </form>

        `,
    props: ['lawfirm', 'invitationCode'],
    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        fetch('/api/countries?exclude=US,UM')
            .then((response) => response.json())
            .then((countries) => {
                this.countries = countries
            })

        if (this.lawfirm && this.lawfirm.email) {
            this.initialEmailAddress = this.lawfirm.email
        }
    },
    data: function () {
        return {
            fields: {
                cocNumber: this.lawfirm ? this.lawfirm.cocNumber : null,
                street: this.lawfirm ? this.lawfirm.street : null,
                housenumber: this.lawfirm ? this.lawfirm.housenumber : null,
                zipcode: this.lawfirm ? this.lawfirm.zipcode : null,
                city: this.lawfirm ? this.lawfirm.city : null,
                state: this.lawfirm ? this.lawfirm.state : null,
                email: this.lawfirm ? this.lawfirm.email : null,
                telephone: this.lawfirm ? this.lawfirm.telephone : null,
                firmName: this.lawfirm ? this.lawfirm.firmName : null,
                countryId: this.lawfirm ? this.lawfirm.countryId : null,
                vatNumber: this.lawfirm ? this.lawfirm.vatNumber : null,
            },
            countries: [],
            initialEmailAddress: null,
        }
    },
    validations() {
        let validationRules = {
            fields: {
                firmName: { required },
                cocNumber: { required },
                vatNumber: { required },
                street: { required },
                housenumber: { required },
                zipcode: { required },
                city: { required },
                email: {
                    required,
                    email,
                    isUnique: helpers.withMessage('This email address is already registered in our database', helpers.withAsync(this.isEmailTaken)),
                },
                telephone: { required },
                countryId: { required },
            },
        }

        validationRules.fields.vatNumber = {
            isUnique: helpers.withMessage(
                "This is not a valid VAT number. If you don't have your VAT number on hand, you can leave this field blank.",
                helpers.withAsync(this.isVatNumberValid),
            ),
        }

        return validationRules
    },
    methods: {
        async submitForm() {
            const valid = await this.v$.$validate()

            if (valid) {
                this.$refs.lawfirmForm.submit()
            }
        },

        async isEmailTaken(value) {
            if (!value) {
                return true
            }
            let isTaken = false

            let url = `/api/check-email/${value}`
            if (this.initialEmailAddress !== null) {
                url += '?exclude=' + this.initialEmailAddress
            }

            await fetch(url)
                .then((result) => {
                    return result.json()
                })
                .then((json) => {
                    if (json.result == 'unavailable') {
                        isTaken = true
                    }
                })
            return !isTaken
        },
        async isVatNumberValid(value) {
            if (!value) {
                return true
            }
            let isValid = false
            let url = `/api/check-vat-number/${value}`

            if (this.fields.countryId !== null) {
                url += '?countryCode=' + this.getCountryCode(this.fields.countryId)
            }

            await fetch(url)
                .then((result) => {
                    return result.json()
                })
                .then((json) => {
                    if (json.result == 'valid') {
                        isValid = true
                    }
                })
            return isValid
        },
        isNL(countryId) {
            let isNL = false
            this.countries.every((country) => {
                if (country.id === countryId) {
                    isNL = country.country_code == 'NL'
                    return false
                }
                return true
            })
            return isNL
        },
        getCountryCode(countryId) {
            let countryCode = null
            this.countries.every((country) => {
                if (country.id === countryId) {
                    countryCode = country.country_code
                    return false
                }
                return true
            })
            return countryCode
        },
    },
    watch: {
        'fields.vatNumber': function (newValue, oldValue) {
            this.fields.vatNumber = newValue.replace(/[^a-zA-Z0-9]+/g, '').toUpperCase()
        },
    },
}

export { LawfirmForm }
