import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

const StartClientRegistrationForm = {
    template: /*html*/ `

        <div class="form-group">
            <div class="grid p-fluid">
                <div class="col-12">
                    <div class="p-inputgroup">
                        <InputText :class="{'p-invalid': v$.fields.clientName.$error}" placeholder="Your name *" v-model="fields.clientName" />
                    </div>
                    <small v-for="error of v$.fields.clientName.$errors" class="p-error">{{error.$message}}</small>
                </div>                            
                <div class="col-12">
                    <div class="p-inputgroup">
                        <InputText :class="{'p-invalid': v$.fields.clientEmail.$error}" placeholder="Your email address *" v-model="fields.clientEmail" />
                    </div>
                    <small v-for="error of v$.fields.clientEmail.$errors" class="p-error">{{error.$message}}</small>
                </div>
                <div class="col-12">
                    <div class="p-inputgroup">
                        <button class="button button-primary" @click="startRegistration">Start registration&nbsp;&nbsp;<i class="pi pi-chevron-right"></i></button>
                    </div>
                </div>                
            </div>
        </div>

        <form ref="registerForm" action="" method="post">
            <input type="hidden" name="client-name" :value="fields.clientName">
            <input type="hidden" name="client-email" :value="fields.clientEmail">
        </form>

        `,
    props: ['lawfirm', 'invitationCode'],
    setup() {
        return { v$: useVuelidate() }
    },
    data: function () {
        return {
            fields: {
                clientName: this.client ? this.client.clientName : null,
                clientEmail: this.client ? this.client.clientEmail : null,
            },
        }
    },
    validations() {
        let validationRules = {
            fields: {
                clientName: { required },
                clientEmail: {
                    required,
                    email,
                },
            },
        }

        return validationRules
    },
    methods: {
        async startRegistration() {
            const valid = await this.v$.$validate()

            if (valid) {
                this.$refs.registerForm.submit()
            }
        },
    },
}

export { StartClientRegistrationForm }
